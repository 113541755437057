import React,{useState} from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {setDoc, getDoc, doc} from "firebase/firestore"; 
import {db} from '../Firebase'

export default function SaveWatchlistForm(props) {

    const [savedWatchlistName, setSavedWatchlistName] = useState(props.selectedWatchlistName);
    const [error, setError] = useState('');

    const handleSavedWatchlistNameChange = (event) => {
        setSavedWatchlistName(event.target.value);
    };

    const handleSaveButtonClick = async() => {

        const userDocRef = doc(db, "users", props.user.email);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const Watchlists = userDocSnapshot.data().Watchlists || {};
          if (Watchlists.hasOwnProperty(savedWatchlistName)) {
            Watchlists[savedWatchlistName] = props.data;

            // Save the updated watchlists to Firestore
            await setDoc(userDocRef, {
              Watchlists: Watchlists
            });
        
            setError('');
            props.onClose();
          } 
          else {
            await setDoc(userDocRef, {
              Watchlists: {
                ...Watchlists,
                [savedWatchlistName]: {
                  ...props.data
                }
              }
            }, { merge: true });
            setError('');
            props.onClose();
          }
        } else {
            setError('User document not found');
        }
    };

    const handleCancelClick = () =>{
        props.onClose();
    };

    if (props.data === null || props.data.length === 0) {
        return (
          <Dialog open={true}>
            <IconButton
                edge="end"
                color="inherit"
                onClick={handleCancelClick}
                style={{ position: 'absolute', top: 0, right: 15}}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle textAlign='center' style={{marginRight:'20px'}}>Save Watchlist</DialogTitle>
            <DialogContent>
              <div>
                <p style={{textAlign:'center', color: '#999'}}>Watchlist is empty</p>
              </div>
            </DialogContent>
          </Dialog>
        );
    }

    return (
        <Dialog open={true}>
            <IconButton
                edge="end"
                color="inherit"
                onClick={handleCancelClick}
                style={{ position: 'absolute', top: 0, right: 10 }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle textAlign='center'>Save Watchlist</DialogTitle>
            <DialogContent>
                <TextField
                    label="Watchlist Name"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={savedWatchlistName}
                    style={{ paddingTop: '10px' }}
                    onChange={handleSavedWatchlistNameChange}
                    error={Boolean(error)}
                    helperText={error}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button style={{ textAlign: 'center', borderRadius: '8px'}} onClick={handleSaveButtonClick} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
