import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCVxAZXPMe0a7ZtMLUQrrBEg1YZMZAuO_k",
  authDomain: "stock-analyser-bfc4d.firebaseapp.com",
  projectId: "stock-analyser-bfc4d",
  storageBucket: "stock-analyser-bfc4d.appspot.com",
  messagingSenderId: "535347411627",
  appId: "1:535347411627:web:e1eaefa1b01300448e15ee",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
