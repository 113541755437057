import React, {useState, useEffect} from 'react'
import Table from './Table';
import DownloadButton from './DownloadButton';
import ChooseDisplyedColumnForm from './ChooseDisplyedColumnForm';
import ConfigurationSettingFormHorizontalSetup from './ConfigurationSettingFormHorizontalSetup';
import SummaryForm from './SummaryForm';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

export default function HorizontalSetupPage(props) {
  
    const [showChooseDisplayedColumnForm, setShowChooseDisplayedColumnForm] = useState(false);
    const [showConfigurationSettingForm, setShowConfigurationSettingForm] = useState(false);
    const [showSummaryForm, setShowSummaryForm] = useState(false);
    const [horizontalSetupTableData, setHorizontalSetupTableData] = useState(props.data);
    const [hiddenColumns, setHiddenColumns] = useState([props.inputDataColumnNames[3], props.inputDataColumnNames[4], props.inputDataColumnNames[5], props.inputDataColumnNames[6], props.inputDataColumnNames[7], props.inputDataColumnNames[8], props.inputDataColumnNames[9], props.inputDataColumnNames[12], props.inputDataColumnNames[19], props.inputDataColumnNames[20]])
    const [configurationFormDefaultValues, setConfigurationFormDefaultValues] = useState([[], 100, 0, 0, 0, 0, 0, ['DAILY', 'WEEKLY'], 0, [], false, false, false, false, false, false, false, false, false, false])

    useEffect(() => {
        setHorizontalSetupTableData(props.data)
    }, [props.data]);

    const modifyHorizontalSetupTableData = (data) => {
        setHorizontalSetupTableData(data);
    };

    const applyFilter = (data, above200MA, above50MA, above20MA, above21EMA, above10EMA, maxDistance, minOverallRSRating, minRupeeVolumeLastDay, minAvgRupeeVolume30Days, minMarketCap, minFloatVolume, chartTypes, minClosingPrice, operatingIndustry, isMAsEnabled, isMaxDistanceEnabled, isMinOverallRSEnabled, isMinRupeeVolumeLastDayEnabled, isMinAvgRupeeVolume30DaysEnabled, isMinMarketCapEnabled, isMinFloatVolumeEnabled, isChartTypesEnabled, isMinClosingPriceEnabled, isOperatingIndustryEnabled) => {
        const filteredData = data.filter(item =>
            (!isMAsEnabled || above200MA === false || item[props.inputDataColumnNames[5]] === true) &&
            (!isMAsEnabled || above50MA === false || item[props.inputDataColumnNames[6]] === true) &&
            (!isMAsEnabled || above20MA === false || item[props.inputDataColumnNames[7]] === true) &&
            (!isMAsEnabled || above21EMA === false || item[props.inputDataColumnNames[8]] === true) &&
            (!isMAsEnabled || above10EMA === false || item[props.inputDataColumnNames[9]] === true) &&
            (!isMinOverallRSEnabled || item[props.inputDataColumnNames[0]] > minOverallRSRating) &&
            (!isMaxDistanceEnabled || item[props.inputDataColumnNames[10]] < maxDistance) &&
            (!isMinRupeeVolumeLastDayEnabled || item[props.inputDataColumnNames[11]] > minRupeeVolumeLastDay) &&
            (!isMinAvgRupeeVolume30DaysEnabled || item[props.inputDataColumnNames[12]] > minAvgRupeeVolume30Days) &&
            (!isMinMarketCapEnabled || item[props.inputDataColumnNames[17]] > minMarketCap) &&
            (!isMinFloatVolumeEnabled || item[props.inputDataColumnNames[19]] > minFloatVolume) &&
            (!isChartTypesEnabled || chartTypes.includes(item[props.inputDataColumnNames[3]])) &&
            (!isMinClosingPriceEnabled || item[props.inputDataColumnNames[4]] > minClosingPrice) &&
            (!isOperatingIndustryEnabled || operatingIndustry.includes(item[props.inputDataColumnNames[18]]))
        );
    
        return filteredData;
    };

    const filterData = (above200MA, above50MA, above20MA, above21EMA, above10EMA, maxDistance, minOverallRSRating, minRupeeVolumeLastDay, minAvgRupeeVolume30Days, minMarketCap, minFloatVolume, chartTypes, minClosingPrice, operatingIndustry, isMAsEnabled, isMaxDistanceEnabled, isMinOverallRSEnabled, isMinRupeeVolumeLastDayEnabled, isMinAvgRupeeVolume30DaysEnabled, isMinMarketCapEnabled, isMinFloatVolumeEnabled, isChartTypesEnabled, isMinClosingPriceEnabled, isOperatingIndustryEnabled) =>{
        // const filteredData = props.data.filter(item => (!isMAsEnabled || above200MA === false || item[props.inputDataColumnNames[5]] === true) && (!isMAsEnabled || above50MA === false || item[props.inputDataColumnNames[6]] === true) && (!isMAsEnabled || above20MA === false || item[props.inputDataColumnNames[7]] === true) && (!isMAsEnabled || above21EMA === false || item[props.inputDataColumnNames[8]] === true) && (!isMAsEnabled || above10EMA === false || item[props.inputDataColumnNames[9]] === true) && (!isMinOverallRSEnabled || item[props.inputDataColumnNames[0]] > minOverallRSRating) && (!isMaxDistanceEnabled || item[props.inputDataColumnNames[10]] < maxDistance) && (!isMinRupeeVolumeLastDayEnabled || item[props.inputDataColumnNames[11]] > minRupeeVolumeLastDay) && (!isMinAvgRupeeVolume30DaysEnabled || item[props.inputDataColumnNames[12]] > minAvgRupeeVolume30Days) && (!isMinMarketCapEnabled || item[props.inputDataColumnNames[17]] > minMarketCap) && (!isMinFloatVolumeEnabled || item[props.inputDataColumnNames[19]] > minFloatVolume) && (!isChartTypesEnabled || chartTypes.includes(item[props.inputDataColumnNames[3]])) && (!isMinClosingPriceEnabled || item[props.inputDataColumnNames[4]] > minClosingPrice) && (!isOperatingIndustryEnabled || operatingIndustry.includes(item[props.inputDataColumnNames[18]])));
        const filteredData = applyFilter(props.data, above200MA, above50MA, above20MA, above21EMA, above10EMA, maxDistance, minOverallRSRating, minRupeeVolumeLastDay, minAvgRupeeVolume30Days, minMarketCap, minFloatVolume, chartTypes, minClosingPrice, operatingIndustry, isMAsEnabled, isMaxDistanceEnabled, isMinOverallRSEnabled, isMinRupeeVolumeLastDayEnabled, isMinAvgRupeeVolume30DaysEnabled, isMinMarketCapEnabled, isMinFloatVolumeEnabled, isChartTypesEnabled, isMinClosingPriceEnabled, isOperatingIndustryEnabled)
        setHorizontalSetupTableData(filteredData)
    };

    const handleShowChooseDisplayedColumnFormClick = () => {
        setShowChooseDisplayedColumnForm(true);
    };

    const handleShowConfigurationSettingFormClick = () => {
        setShowConfigurationSettingForm(true);
    };

    const handleShowSummaryFormClick = () => {
        setShowSummaryForm(true);
    };

    const handleCopyClick = () => {
        // Get the first column cells from the table
        const firstColumnCells = horizontalSetupTableData.map(obj => obj[Object.keys(obj)[0]]);;
    
        // Extract the text from each cell and join it into a single string
        const textToCopy = firstColumnCells.join(',');
    
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
    
        // Append the textarea element to the DOM and select its content
        document.body.appendChild(textarea);
        textarea.select();
    
        // Execute the copy command to copy the selected text
        document.execCommand('copy');
    
        // Remove the textarea element from the DOM
        document.body.removeChild(textarea);
    };

    const handleSearch = (e) => {

        const serchFilteredData = props.data.filter((item) =>
            Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
        const configurationFilteredData = applyFilter(serchFilteredData, configurationFormDefaultValues[0].includes(props.inputDataColumnNames[5]), configurationFormDefaultValues[0].includes(props.inputDataColumnNames[6]), configurationFormDefaultValues[0].includes(props.inputDataColumnNames[7]), configurationFormDefaultValues[0].includes(props.inputDataColumnNames[8]), configurationFormDefaultValues[0].includes(props.inputDataColumnNames[9]), configurationFormDefaultValues[1], configurationFormDefaultValues[2], configurationFormDefaultValues[3], configurationFormDefaultValues[4], configurationFormDefaultValues[5], configurationFormDefaultValues[6], configurationFormDefaultValues[7], configurationFormDefaultValues[8], configurationFormDefaultValues[9], configurationFormDefaultValues[10], configurationFormDefaultValues[11], configurationFormDefaultValues[12], configurationFormDefaultValues[13], configurationFormDefaultValues[14], configurationFormDefaultValues[15], configurationFormDefaultValues[16], configurationFormDefaultValues[17], configurationFormDefaultValues[18], configurationFormDefaultValues[19])

        setHorizontalSetupTableData(configurationFilteredData)
    };

    return (
        <div>
            <h5 className='centered' style={{ padding: '5px' }}>{props.pageTitle}</h5>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <span
                        className="summary-button"
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleShowSummaryFormClick();
                        }}
                    >
                        Setup Summary
                    </span> 
                    <span
                        className="copy-symbol-button"
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleCopyClick();
                        }}
                    >
                        Copy Symbols
                    </span>  
                    <span
                        className="settings-button"
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleShowConfigurationSettingFormClick();
                        }}
                    >
                        Apply Filters
                    </span>              
                    <span
                        className="displyed-column-button"
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleShowChooseDisplayedColumnFormClick();
                        }}
                    >
                        Hide/Display Data
                    </span>    
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                            variant="outlined"
                            placeholder='...search'
                            onChange={handleSearch}
                            style={{ marginRight: 10 }}
                            size="small"
                    />
                    <SearchIcon style={{ color: '#555', cursor: 'pointer' }} />  
                </div>
            </div>    
            {showConfigurationSettingForm && (
                <ConfigurationSettingFormHorizontalSetup key="horizontalSetupConfigurationSettingForm" filterData = {filterData} hideColumns = {setHiddenColumns} inputDataColumnNames = {props.inputDataColumnNames} defaultValues = {configurationFormDefaultValues} setDefaultValues = {setConfigurationFormDefaultValues} operatingIndustryOptions={Array.from(new Set(props.data.map(stock => stock[props.inputDataColumnNames[18]]))).sort((a, b) => a.localeCompare(b))} onClose={() => setShowConfigurationSettingForm(false)} />
            )}
            {showChooseDisplayedColumnForm && (
                <ChooseDisplyedColumnForm key="horizontalSetupDisplyedColumnForm" options = {Object.keys(props.data[0])} hideColumns = {setHiddenColumns} hiddenColumns = {hiddenColumns} onClose={() => setShowChooseDisplayedColumnForm(false)} />
            )}
            {showSummaryForm && (
                <SummaryForm key="horizontalSetupSummaryForm"  data={props.data} inputDataColumnNames = {props.inputDataColumnNames} onClose={() => setShowSummaryForm(false)} />
            )}
            <Table key="horizontalSetupTable" data={horizontalSetupTableData} modifyData = {modifyHorizontalSetupTableData} hiddenColumns = {hiddenColumns} hideColumns = {setHiddenColumns} inputDataColumnNames = {props.inputDataColumnNames} itemsPerPage = {50}/>
            <DownloadButton key="horizontalSetupDownloadButton" text="Download" downloadFilename="Horizontal Setup"  data={horizontalSetupTableData} hiddenColumns={hiddenColumns}/>
        </div>
    )
}
