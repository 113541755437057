import React from 'react'
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';

export default function Blogs() {
  const blogs = [
    {
      id: 1,
      title: 'Getting Started',
      slug: 'getting-started',
      image: '/gettingStartedBlog.jpg'
    },
    {
      id: 2,
      title: 'Setups of Interest',
      slug: 'setup-of-interest',
      image: '/setupsOfInterestBlog.jpg'
    },
    {
      id: 3,
      title: 'Market and Sector Analysis',
      slug: 'market-and-sector-analysis',
      image: '/marketAnalysisBlog.png'
    },
    {
      id: 4,
      title: 'Other Usefull Tools',
      slug: 'other-usefull-tools',
      image: '/otherUsefullTools.png'
    },
  ];

  return (
    <div>
      <h5 className='centered' style={{ padding: '5px' }}>Blogs</h5>
      <Container  sx={{ mt: 4, marginLeft:15, marginTop:5 }}>
        <Grid container spacing={8}>
          {blogs.map(blog => (
            <Grid item key={blog.id} xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={blog.image}
                  alt={blog.title}
                />
                <CardContent>
                  <Link to={`/blogs/${blog.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="h6" component="div" textAlign='center'>
                      {blog.title}
                    </Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
