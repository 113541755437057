import React from 'react'
import { Typography} from '@mui/material';
import { Link } from 'react-router-dom';

export default function OtherUsefullToolsBlog() {
  return (
    <div>
        <h5 className='centered' style={{ padding: '5px' }}>Other Usefull Tools</h5>
        <div style={{ marginLeft:'40px', marginRight:'40px', marginTop:'10px'}}>
            <Typography paragraph>
                One of the major pain points mentioned by plenty of traders is the confusion to choose a stock to trade when multiple alerts are triggered.
                Sounds relatable? Well, let's take a step back and examine why this is the case. 
            </Typography>
            <Typography paragraph>
                For traders, prioritizing a watch list after narrowing down names based on technical chart patterns requires analysing factors ranging from sector strength to examining the fundamentals of the selected stocks. 
                Achieving this is relatively straightforward when dealing with fewer than five shortlisted stocks, simplifying decision-making. However, the challenge intensifies when working with a larger list, 
                as the necessary information for analysis is not only scattered across different platforms but also requires consolidating the gathered information to facilitate quick prioritization at a single glance.
            </Typography>
            <Typography paragraph>
                Introducing our <Link to="/watch-list">Create Watchlist</Link> feature where you can add stocks from your watch list and analyse RS rating, industry strength, fundamentals at one single space. 
                This way, one can de-prioritise weak names quickly and focus only on strong names.
            </Typography>
            <Typography paragraph>
                Finally, an additional parameter that is of interest to most traders is access to relative strength rating of a stock. Just to clarify, 
            </Typography>
            <Typography paragraph>
                RS rating = 40 &times; Relative performance of stock wrt Nifty in 3 months + 20 &times; (relative performance in 6 months + 9 months+ 12 months)
            </Typography>
            <Typography paragraph>
                You can sort all the NSE listed stocks according to RS rating(and other parameters too). It's like a generic filter to quickly cut down weak names or illiquid names and export strong names to Trading view.
            </Typography>
        </div>
    </div>
  )
}
