import React from 'react'
import { Typography} from '@mui/material';
import { Link } from 'react-router-dom';

export default function SetupsOfInterestBlog() {
  return (
    <div>
        <h5 className='centered' style={{ padding: '5px' }}>Setups of Interest</h5>
        <div style={{ marginLeft:'40px', marginRight:'40px', marginTop:'10px'}}>
            <Typography paragraph>
              In part 2 of our series, let's dive into <strong>Setups of Interest</strong> in detail. 
              Currently there are so many fancy stock market scanners and tools available, why is there a need for one more? Justifying the need requires more than a single statement.
              However, after reading this blog, you'll have the opportunity to assess its value and decide for yourself.
            </Typography>
            <Typography variant="h7" style={{marginBottom:'10px'}} component="div">
              <strong>Simple Horizontal Setup :-</strong>
            </Typography>
            <Typography paragraph>
              This is the most preferred setup among traders characterized by a simple horizontal resistance line. 
              Currently there is no simple way to arrive at such setups apart from manual scanning.
              Moreover, existing tools and scanners signal a stock post breakout.  This is where our <Link to="/horizontal-setup/daily-setup">Horizontal Setup Scanner</Link> comes handy.
              It assist you in pinpointing stocks that have a horizontal resistance line with price near the resistance, but haven't breached it yet. 
            </Typography>
            <img style={{maxWidth: '80%', height: 'auto', marginTop: '15px', marginBottom:'15px', marginLeft:'120px'}} src="/horizontalSetupExample.png" alt="Horizontal Setup" />
            <Typography paragraph>
              Few people prefer to take long position in weekly/long base setups. These set of names are available in <Link to="/horizontal-setup/long-base">Long Base</Link> option under horizontal setup scanner. 
            </Typography>
            <Typography variant="h7" style={{marginBottom:'10px'}} component="div">
              <strong>Tight Setup :-</strong>
            </Typography>
            <Typography paragraph>
              Stocks exhibiting a narrow range often present a favourable risk-reward ratio.
              Although there are couple of scanners available for this kind of setup, they are not accurate in capturing most of the names.
              Our <Link to="/tight-setup">Tight Setup Scanner</Link> addresses this issue, providing quality names by identifying consolidation of a stock in the following scenarios,
            </Typography>
            <ol style={{marginLeft:'17px'}}>
                <li>
                  Tightness on the right side of base formation, with close proximity to key moving averages
                  <div style={{ marginTop: '15px', marginBottom: '15px', marginLeft: '65px' }}>
                    <img style={{ maxWidth: '87%', height: 'auto' }} src="/tightSetupExample.png" alt="Tight Setup" />
                  </div>
                  Sometimes the consolidation happens below key moving averages as well, these are also listed in tight setup scanner.
                </li>
                <li style={{marginTop:'10px'}}>
                  Consolidating at new highs without pull back, often referred to as strong hands 
                  <div style={{ marginTop: '15px', marginBottom: '15px', marginLeft: '60px' }}>
                    <img style={{ maxWidth: '87%', height: 'auto' }} src="/strongHands.png" alt="Strong Hands" />
                  </div>
                </li>
            </ol> 
            <Typography variant="h7" style={{marginBottom:'10px'}} component="div">
              <strong>Tips to use for Horizontal and Tight setups scanners :-</strong>
            </Typography> 
            <ol style={{marginLeft:'17px' }}>
                <li>
                  For traders who enjoys and find value in manual scanning, this scanner serves as a complementary resource for discovering additional setups or confirming existing ones
                </li>
                <li style={{marginTop:'10px'}}>
                  For busy working professionals with limited time for frequent scanning, this tool is ideal for swiftly identifying potential horizontal setups to trade
                </li>
            </ol>
            <Typography variant="h7" style={{marginBottom:'10px'}}component="div">
              <strong>Setups with Accumulation in Base :-</strong>
            </Typography>
            <Typography paragraph>
              One of the simple ways to make money in stock markets is to follow where institutional money is flowing.
              As an outsider, the only way to recognize this is to identify stocks with huge volume footprints in the charts confirming accumulation by big hands.
              This also suggests huge buying force and upcoming big move. The <Link to="/volume-footprint">Volume Footprint Scanner</Link> does good job at identifying stocks in the process of forming a base with substantial accumulation.
            </Typography>
            <img style={{maxWidth: '80%', height: 'auto', marginTop: '15px', marginBottom:'15px', marginLeft:'101px'}} src="/volumeFootprint.png" alt="Volume Footprint" />
            <Typography variant="h7" style={{marginBottom:'10px'}}  component="div">
              <strong>IPO Setup :-</strong>
            </Typography>
            <Typography paragraph>
              Stocks coming out of IPO bases generate good short term move and that's what attracts trader to these kind of stocks. 
              Although the access to IPO names is straightforward from NSE website, updating recent listing every time and scanning through them involves a bit of hassle.
            </Typography>
            <ol style={{marginLeft:'17px' }}>
                <li>
                  If you like to manually scan IPO names, you have the option to select the IPO stock listing date of your preference and copy the names to Trading View directly in <Link to="/ipo-master-list">IPO Masterlist</Link> 
                </li>
                <li style={{marginTop:'10px'}}>
                  IPO stocks forming horizontal and tight setups are available in <Link to="/ipo-alerts">IPO Alerts</Link>
                  <div style={{ marginTop: '15px', marginBottom: '15px', marginLeft: '50px' }}>
                    <img style={{ maxWidth: '86%', height: 'auto' }} src="/IPOAlert.png" alt="IPO Alert" />
                  </div>
                </li>
            </ol>
            <Typography variant="h7" style={{marginBottom:'10px'}}  component="div">
              <strong>Conclusion :-</strong>
            </Typography>
            <Typography paragraph>
              In summary, while traders often seek specific setups that are not readily available except through manual scanning,
              our custom-built sophisticated scanners not only simplify but also streamline your scanning process, making it both easy and seamless.
            </Typography> 
        </div>
    </div>
  )
}
