import React, {useState, useEffect} from 'react'
import Table from './Table';
import DownloadButton from './DownloadButton';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { PacmanLoader } from 'react-spinners';

export default function IPOAlerts(props) {

    const [IPOAlertTableData, setIPOAlertTableData] = useState(null);
    const [IPOAlertData, setIPOAlertData] = useState(null);
    const hiddenColumns = [props.inputDataColumnNames[0], props.inputDataColumnNames[1], props.inputDataColumnNames[2], props.inputDataColumnNames[3], props.inputDataColumnNames[4], props.inputDataColumnNames[5], props.inputDataColumnNames[6], props.inputDataColumnNames[7], props.inputDataColumnNames[8], props.inputDataColumnNames[9], props.inputDataColumnNames[10], props.inputDataColumnNames[12], props.inputDataColumnNames[13], props.inputDataColumnNames[14], props.inputDataColumnNames[15], props.inputDataColumnNames[16], props.inputDataColumnNames[17], props.inputDataColumnNames[19], props.inputDataColumnNames[20], props.inputDataColumnNames[21], props.inputDataColumnNames[22], props.inputDataColumnNames[23], props.inputDataColumnNames[24], props.inputDataColumnNames[25], props.inputDataColumnNames[29], props.inputDataColumnNames[30], props.inputDataColumnNames[33]]
    const [listingDateFilter, setListingDateFilter] = useState(new Date("2023-01-01"));

    useEffect(() => {        
        const sectorDataDictionary = {};
        props.sectorialAnalysisData.forEach(item => {
            sectorDataDictionary[item[props.inputDataColumnNames[18]]] = item;
        });
    
        const dataWithAlertTypeAdded = props.basicRSData.map(itemBasicRS => {
          const matchingItemHorizontal = props.horizontalSetupData.find(itemHorizontal => itemHorizontal[props.inputDataColumnNames[31]] === itemBasicRS[props.inputDataColumnNames[31]]);
          const matchingItemTight = props.tightSetupData.find(itemTight => itemTight[props.inputDataColumnNames[31]] === itemBasicRS[props.inputDataColumnNames[31]]);
        
          let alertType = ['Alert Type', 'None']

          if (matchingItemHorizontal && matchingItemTight) {
            alertType = ['Alert Type', 'Horizontal Setup and Tight Setup'];
          } else if (matchingItemHorizontal) {
            alertType = ['Alert Type', 'Horizontal Setup'];
          }else if(matchingItemTight){
            alertType = ['Alert Type', 'Tight Setup'];;
          }

          const keyValuePairs = Object.entries(itemBasicRS);
          keyValuePairs.splice(1, 0, alertType);
          const updatedItemBasicRS = Object.fromEntries(keyValuePairs);

          return updatedItemBasicRS;
        });

        const dataWhichIsHavinAtleast1Alert = dataWithAlertTypeAdded.filter(item => item['Alert Type'] !== 'None');

        const mergedData = dataWhichIsHavinAtleast1Alert.map(item => {
          const matchingItem = sectorDataDictionary[item[props.inputDataColumnNames[18]]];
          return { ...item, ...matchingItem };
        });
        
        const sortedData = mergedData.sort((a, b) => {
            const getDateFromString = (dateString) => {
                const [day, month, year] = dateString.split('/');
                return new Date(`${month}/${day}/${year}`);
            };
            
            const dateA = getDateFromString(a[props.inputDataColumnNames[34]]);
            const dateB = getDateFromString(b[props.inputDataColumnNames[34]]);
        
            return dateB - dateA;
        })

        const filteredData = mergedData.filter(item => {
            const [day, month, year] = item[props.inputDataColumnNames[34]].split('/');
            const formattedDateString = `${month}/${day}/${year}`;
            const itemDate = new Date(formattedDateString);
            const cutOffDate = new Date("2023-01-01");
            return itemDate.getTime() > cutOffDate.getTime();
        });
        
        setIPOAlertTableData(filteredData);
        setIPOAlertData(sortedData);
    }, [props.sectorialAnalysisData, props.basicRSData, props.horizontalSetupData, props.tightSetupData, props.inputDataColumnNames]);

    if (IPOAlertData === null || IPOAlertData.length === 0) {
        return  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                  <PacmanLoader color="#1e88e5" size={25} margin={5} />
                </div>
    }
    
    const handleDateChange = (newDate) => {
        setListingDateFilter(newDate);
        const filteredData = IPOAlertData.filter(item => {
            const itemDate = new Date(item[props.inputDataColumnNames[34]]);
            return itemDate.getTime() > newDate.getTime();
        });
        
        setIPOAlertTableData(filteredData);
    }

    const modifyIPOAlertTableData = (data) => {
        setIPOAlertTableData(data);
    };

    const handleCopyClick = () => {
        // Get the first column cells from the table
        const firstColumnCells = IPOAlertTableData.map(obj => obj[Object.keys(obj)[0]]);;
    
        // Extract the text from each cell and join it into a single string
        const textToCopy = firstColumnCells.join(',');
    
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
    
        // Append the textarea element to the DOM and select its content
        document.body.appendChild(textarea);
        textarea.select();
    
        // Execute the copy command to copy the selected text
        document.execCommand('copy');
    
        // Remove the textarea element from the DOM
        document.body.removeChild(textarea);
    };

    const handleSearch = (e) => {

        const serchFilteredData = IPOAlertData.filter((item) =>
            Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(e.target.value.toLowerCase())
            )
        );

        setIPOAlertTableData(serchFilteredData)
    };

    return (
        <div>
            <h5 className='centered' style={{ padding: '5px' }}>IPO Alerts</h5>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <span
                        className="copy-symbol-button"
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleCopyClick();
                        }}
                        style={{marginLeft:'17px'}}
                    >
                        Copy Symbols
                    </span>  
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px', fontSize: '1.1em' }}>
                        Listing date {'>'}
                    </span>           
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={listingDateFilter}
                            format="yyyy/MM/dd"
                            onChange={(newDate) => handleDateChange(newDate)}
                            slotProps={{ textField: { size: 'small', readOnly: true} }}
                        />
                    </LocalizationProvider>
                    <TextField
                            variant="outlined"
                            placeholder='...search'
                            onChange={handleSearch}
                            style={{ marginRight: 10, marginLeft:'20px'}}
                            size="small"
                    />
                    <SearchIcon style={{ color: '#555', cursor: 'pointer' }} />  
                </div>
            </div>       
            <Table key="IPOAlertTable" data={IPOAlertTableData} modifyData = {modifyIPOAlertTableData} hiddenColumns = {hiddenColumns} inputDataColumnNames = {props.inputDataColumnNames} itemsPerPage = {50}/>
            <DownloadButton key="IPOAlertDownloadButton" text="Download" downloadFilename="IPO Alerts"  data={IPOAlertTableData} hiddenColumns={hiddenColumns}/>
        </div>
    )
}
