import React,{useState} from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Checkbox,
    IconButton,
    Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {db} from '../Firebase'
import {  doc, updateDoc, getDoc} from 'firebase/firestore';

export default function DeleteWatchlistForm(props) {

  const [watchlistToDelete, setWatchlistToDelete] = useState([])

  const handleCancelClick = () =>{
    props.onClose();
  };

  const handleResetClick = () =>{
    setWatchlistToDelete([]);
  };

  const handleDeleteClick = async() =>{
    try {

      const userDocRef = doc(db, 'users', props.user.email);
      const snapshot = await getDoc(userDocRef);

      if (snapshot.exists()) {
        const userData = snapshot.data();
        const currentWatchlists = userData.Watchlists;
      
        if (currentWatchlists) {

          const updatedWatchlists = { ...currentWatchlists };
          watchlistToDelete.forEach((watchlist) => {
            delete updatedWatchlists[watchlist];
          });
          await updateDoc(userDocRef, { Watchlists: updatedWatchlists });
        } 
      }
      props.onClose();

    } catch (error) {
      console.error('Error deleting watchlists:', error.message);
    }
  };

  const handleWatchlistToDeleteChange=(watchlistName) =>{
    const index = watchlistToDelete.indexOf(watchlistName);

    if (index !== -1) {
        const updatedWatchlistToDelete = [...watchlistToDelete];
        updatedWatchlistToDelete.splice(index, 1);
        setWatchlistToDelete(updatedWatchlistToDelete);
    } else {
        setWatchlistToDelete([...watchlistToDelete, watchlistName]);
    }
  }

  if (!props.watchlists || Object.keys(props.watchlists).length === 0) {
    return (
      <Dialog open={true}>
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleCancelClick}
            style={{ position: 'absolute', top: 0, right: 15}}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle textAlign='center' style={{marginRight:'20px'}}>Delete Watchlists</DialogTitle>
        <DialogContent>
          <div>
            <p style={{textAlign:'center', color: '#999'}}>No watchlists to delete</p>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={true}>
      <IconButton
          edge="end"
          color="inherit"
          onClick={handleCancelClick}
          style={{ position: 'absolute', top: 0, right: 10 }}
      >
          <CloseIcon />
      </IconButton>
      <DialogTitle textAlign='center'>Delete Watchlists</DialogTitle>
      <DialogContent>
        {Object.entries(props.watchlists).map(([watchlistName, stocks]) => (
            <div key={watchlistName}>
              <Checkbox
                  checked={watchlistToDelete.includes(watchlistName)}
                  onChange={() => handleWatchlistToDeleteChange(watchlistName)}
              />
              <Tooltip style={{fontSize:'5'}} title={Object.values(stocks).join(', ')} arrow>
                <label>
                    {watchlistName}
                </label>
              </Tooltip>
            </div>
        ))}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button  style={{ textAlign: 'center', borderRadius: '8px'}} onClick={handleResetClick}>Reset</Button>
        <Button  style={{ textAlign: 'center', borderRadius: '8px'}} variant="contained" color="primary" onClick={handleDeleteClick}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
