import React, {useState, useEffect} from 'react'
import Table from './Table';
import DownloadButton from './DownloadButton';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { PacmanLoader } from 'react-spinners';

export default function VolumeFootprint(props) {
    const [volumeFootprintTableData, setVolumeFootprintTableData] = useState(null);
    const [volumeFootprintData, setVolumeFootprintData] = useState(null);
    const hiddenColumns = [props.inputDataColumnNames[21], props.inputDataColumnNames[22], props.inputDataColumnNames[23], props.inputDataColumnNames[24], props.inputDataColumnNames[25], props.inputDataColumnNames[29], props.inputDataColumnNames[30]]

    useEffect(() => {        
        const sectorDataDictionary = {};
        props.sectorialAnalysisData.forEach(item => {
            sectorDataDictionary[item[props.inputDataColumnNames[18]]] = item;
        });
    
        const mergedData = props.data.map(item => {
          const matchingItem = sectorDataDictionary[item[props.inputDataColumnNames[18]]];
          return { ...item, ...matchingItem };
        });
 
        setVolumeFootprintTableData(mergedData);
        setVolumeFootprintData(mergedData);
    }, [props.sectorialAnalysisData, props.data, props.inputDataColumnNames]);

    if (volumeFootprintData === null || volumeFootprintData.length === 0) {
        return  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                  <PacmanLoader color="#1e88e5" size={25} margin={5} />
                </div>
    }

    const handleCopyClick = () => {
        // Get the first column cells from the table
        const firstColumnCells = volumeFootprintTableData.map(obj => obj[Object.keys(obj)[0]]);;
    
        // Extract the text from each cell and join it into a single string
        const textToCopy = firstColumnCells.join(',');
    
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
    
        // Append the textarea element to the DOM and select its content
        document.body.appendChild(textarea);
        textarea.select();
    
        // Execute the copy command to copy the selected text
        document.execCommand('copy');
    
        // Remove the textarea element from the DOM
        document.body.removeChild(textarea);
    };

    const handleSearch = (e) => {

        const serchFilteredData = volumeFootprintData.filter((item) =>
            Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(e.target.value.toLowerCase())
            )
        );

        setVolumeFootprintTableData(serchFilteredData)
    };

    const modifyVolumeFootprintTableData = (data) => {
        setVolumeFootprintTableData(data);
    };

    return (
        <div>
            <h5 className='centered' style={{ padding: '5px' }}>Volume Footprint</h5>
            <p style={{ fontSize: '18px', marginLeft:'21px' }}><strong>Note:</strong></p>
            <ol style={{marginLeft:'17px' }}>
                <li>The below are identified list of Small and Midcap stocks which aren't out of their 52 week high yet, with accumulation in their base and evident in volume footprints</li>
            </ol>  
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <span
                        className="copy-symbol-button"
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleCopyClick();
                        }}
                        style={{marginLeft:'17px'}}
                    >
                        Copy Symbols
                    </span>  
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                            variant="outlined"
                            placeholder='...search'
                            onChange={handleSearch}
                            style={{ marginRight: 10, marginLeft:'20px'}}
                            size="small"
                    />
                    <SearchIcon style={{ color: '#555', cursor: 'pointer' }} />  
                </div>
            </div>     
            <Table key="volumeFootprintTable" data={volumeFootprintTableData} modifyData = {modifyVolumeFootprintTableData} hiddenColumns = {hiddenColumns} inputDataColumnNames = {props.inputDataColumnNames} itemsPerPage = {50}/>
            <DownloadButton key="volumeFootprintDownloadButton" text="Download" downloadFilename="Volume Footprint"  data={volumeFootprintTableData} hiddenColumns={hiddenColumns}/>
        </div>
    )
}
