import React from 'react'
import { TableContainer} from '@mui/material';
import { PacmanLoader } from 'react-spinners';

export default function TableIndustryInfo(props) {

  // if data supplied by backend is null then we will display as loading
  if (props.data === null || props.data.length === 0) {
    return  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <PacmanLoader color="#1e88e5" size={25} margin={5} />
            </div>
  }

  return (
    <div>
      <TableContainer className='table-container'>
        <table id="myTable" className="table table-bordered my-3">
          <thead style={{textAlign:'left'}} className="table-light">
            <tr>
              {props.data.length > 0 && Object.keys(props.data[0]).map((key, index, array) => (
                  <th key={key}>  
                  {key}
                  </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.data.map((item, index) => (
              <tr key={index}>
                {Object.entries(item).map(([columnKey, value], cellIndex) => (
                <td
                  key={cellIndex}
                >
                  {
                    value === true
                      ? 'True'
                      : value === false
                      ? 'False'
                      : value === -1
                      ? 'NA'
                      : value === null
                      ? 'NA'
                      : value
                  }
                </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  )
}
