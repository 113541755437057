import React,{useState, useEffect} from 'react'
import TableSectorialAnalysis from './TableSectorialAnalysis'
import ChooseDisplyedColumnForm from './ChooseDisplyedColumnForm';
import ConfigurationSettingFormSectorialAnalysis from './ConfigurationSettingFormSectorialAnalysis';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import DownloadButton from './DownloadButton';

export default function SectorialAnalysisPage(props) {

    const [showChooseDisplayedColumnForm, setShowChooseDisplayedColumnForm] = useState(false);
    const [showConfigurationSettingForm, setShowConfigurationSettingForm] = useState(false);
    const [sectorialAnalysisTableData, setSectorialAnalysisTableData] = useState(props.sectorialAnalysisData);
    const [hiddenColumns, setHiddenColumns] = useState([props.inputDataColumnNames[1], props.inputDataColumnNames[2], props.inputDataColumnNames[3], props.inputDataColumnNames[4], props.inputDataColumnNames[5], props.inputDataColumnNames[6], props.inputDataColumnNames[7], props.inputDataColumnNames[8], props.inputDataColumnNames[9], props.inputDataColumnNames[10], props.inputDataColumnNames[12], props.inputDataColumnNames[15], props.inputDataColumnNames[16], props.inputDataColumnNames[19], props.inputDataColumnNames[20], props.inputDataColumnNames[21], props.inputDataColumnNames[25]])
    const [configurationFormDefaultValues, setConfigurationFormDefaultValues] = useState([0, 0, false, false])

    useEffect(() => {
        setSectorialAnalysisTableData(props.sectorialAnalysisData)
    }, [props.sectorialAnalysisData]);

    const modifySectorialAnalysisTableData = (data) => {
        setSectorialAnalysisTableData(data);
    };

    const handleShowChooseDisplayedColumnFormClick = () => {
        setShowChooseDisplayedColumnForm(true);
    };

    const handleShowConfigurationSettingFormClick = () => {
        setShowConfigurationSettingForm(true);
    };

    const applyFilter = (data, minNumberOfStocksInIndustry, minGroupMarketCap, isMinNumberOfStocksInIndustryEnabled, isMinGroupMarketCapEnabled) => {
        const filteredData = data.filter(item =>
            (!isMinNumberOfStocksInIndustryEnabled || item[props.inputDataColumnNames[29]] > minNumberOfStocksInIndustry) &&
            (!isMinGroupMarketCapEnabled || item[props.inputDataColumnNames[30]] > minGroupMarketCap)
        );    
        return filteredData;
    };

    const filterData = (minNumberOfStocksInIndustry, minGroupMarketCap, isMinNumberOfStocksInIndustryEnabled, isMinGroupMarketCapEnabled) =>{
        // const filteredData = props.sectorialAnalysisData.filter(item => (!isMinNumberOfStocksInIndustryEnabled || item[props.inputDataColumnNames[29]] > minNumberOfStocksInIndustry) && (!isMinGroupMarketCapEnabled || item[props.inputDataColumnNames[30]] > minGroupMarketCap));
        const filteredData = applyFilter(props.sectorialAnalysisData, minNumberOfStocksInIndustry, minGroupMarketCap, isMinNumberOfStocksInIndustryEnabled, isMinGroupMarketCapEnabled)
        setSectorialAnalysisTableData(filteredData)
    };

    const handleSearch = (e) => {

        const serchFilteredData = props.sectorialAnalysisData.filter((item) =>
            Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
        const configurationFilteredData = applyFilter(serchFilteredData, configurationFormDefaultValues[0], configurationFormDefaultValues[1], configurationFormDefaultValues[2], configurationFormDefaultValues[3])

        setSectorialAnalysisTableData(configurationFilteredData)
    };

    const handleShowSectorGraphicalViewClick = (e) => {
        // const currently_visible_industries = sectorialAnalysisTableData.map(obj => obj[Object.keys(obj)[0]]);

        // sort data by number of stocks and return pass top 10 results
        const sortedData = sectorialAnalysisTableData.sort((a, b) => b[props.inputDataColumnNames[29]] - a[props.inputDataColumnNames[29]]);
        const top11Results = sortedData.slice(0, 11);
        const top11Industries = top11Results.map(obj => obj[Object.keys(obj)[0]]);
        // if(currently_visible_industries.length === 0)
        // {
        //     alert('No industries available to display');
        //     return
        // }
        // else if(currently_visible_industries.length > 30)
        // {
        //     alert('Too many industries to display. Please reduce industry number to less than or equal to 30');
        //     return
        // }
        const url = `/#/industry-graphical-view/${encodeURIComponent(top11Industries)}`;
        window.open(url, '_blank');
    };

    return (
        <div>
            <h5 className='centered' style={{ padding: '5px' }}>Industry Analysis</h5>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <span
                        className="settings-button-sectorial-analysis"
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleShowConfigurationSettingFormClick();
                        }}
                    >
                        Apply Filters
                    </span> 
                    <span
                        className="displyed-column-button"
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleShowChooseDisplayedColumnFormClick();
                        }}
                    >
                        Hide/Display Data
                    </span> 
                    <span
                        className="show-sector-graphical-view-button"
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleShowSectorGraphicalViewClick();
                        }}
                    >
                        Show Graphical View
                    </span> 
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                            variant="outlined"
                            placeholder='...search'
                            onChange={handleSearch}
                            style={{ marginRight: 10 }}
                            size="small"
                    />
                    <SearchIcon style={{ color: '#555', cursor: 'pointer' }} />  
                </div>
            </div>
            {showConfigurationSettingForm && (
                <ConfigurationSettingFormSectorialAnalysis key="sectorialAnalysisConfigurationSettingForm" filterData = {filterData} hideColumns = {setHiddenColumns} inputDataColumnNames = {props.inputDataColumnNames} defaultValues = {configurationFormDefaultValues} setDefaultValues = {setConfigurationFormDefaultValues} onClose={() => setShowConfigurationSettingForm(false)} />
            )}
            {showChooseDisplayedColumnForm && (
                <ChooseDisplyedColumnForm key="sectorialAnalysisDisplyedColumnForm"   options={Object.keys(props.sectorialAnalysisData[0])}  hideColumns = {setHiddenColumns} hiddenColumns = {hiddenColumns} onClose={() => setShowChooseDisplayedColumnForm(false)} />
            )}
            <TableSectorialAnalysis data = {sectorialAnalysisTableData} modifyData = {modifySectorialAnalysisTableData} hiddenColumns = {hiddenColumns} hideColumns = {setHiddenColumns} inputDataColumnNames = {props.inputDataColumnNames} itemsPerPage = {50}/>
            <DownloadButton key="sectorialAnalysisDownloadButton" text="Download" downloadFilename="Industry Analysis" data={sectorialAnalysisTableData} hiddenColumns={hiddenColumns}/>
        </div>
    )
}
