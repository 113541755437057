import React,{useState} from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

export default function AddingStockInWatchlistForm(props) {

    const [selectedStocks, setSelectedStocks] = useState(props.defaultValues);
    const [overallData, setOverallData] = useState(props.data.map(obj => obj[props.inputDataColumnNames[31]]));

    if (props.data === null || props.data.length === 0) {
        return <div className="centered-content">
                  <p>No Data</p>
                </div> ;
    }

    const handleCancelClick = () =>{
        props.onClose()
    };

    const handleCheckboxChange = (item) => {
        const isSelected = selectedStocks.includes(item);
        const updatedSelectedStocks = isSelected
            ? selectedStocks.filter(stock => stock !== item)
            : [...selectedStocks, item];
        setSelectedStocks(updatedSelectedStocks);        
    };

    const handleApplyClick = () => {
        const filteredData = props.data.filter((item) =>
            selectedStocks.includes(item[props.inputDataColumnNames[31]])
        );
        props.addSelectedStockInWatchlist(filteredData);
        props.onClose();
    };

    const handleResetClick = () => {
        setSelectedStocks([]);
    };

    const handleSearch = (e) => {
        const filteredData = props.data.map(obj => obj[props.inputDataColumnNames[31]]).filter((item) =>
            item.toString().toLowerCase().includes(e.target.value.toLowerCase())
        );
        setOverallData(filteredData)
    };

    return (
        <Dialog open={true}>
            <IconButton
                edge="end"
                color="inherit"
                onClick={handleCancelClick}
                style={{ position: 'absolute', top: 0, right: 10 }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle textAlign='center'>Add Stocks in Watchlist</DialogTitle>
            <DialogContent style={{ maxWidth: '1000px', marginLeft:'10px', marginRight:'10px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                            variant="outlined"
                            placeholder='...search symbol'
                            onChange={handleSearch}
                            style={{ marginRight: 10 }}
                            size="small"
                    />
                    <SearchIcon style={{ color: '#555', cursor: 'pointer' }} />  
                </div>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {overallData.map(item => (
                        <li key={item}>
                            <div style={{alignItems: 'center' }}>
                                <label>{item}</label>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        marginRight: 5,
                                        fontSize: 18, // Adjust the font size as needed
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleCheckboxChange(item)}
                                    >
                                    {selectedStocks.includes(item) ? '❌' : <span style={{ color: '#1e88e5', display: 'inline-block' }}>+</span>}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button  style={{ textAlign: 'center', borderRadius: '8px'}} onClick={handleResetClick}>Reset</Button>
                <Button  style={{ textAlign: 'center', borderRadius: '8px'}} onClick={handleApplyClick} variant="contained" color="primary">
                    Add To Watchlist
                </Button>
            </DialogActions>
        </Dialog>
    )
}
