import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import TableIndustryInfoStocks from './TableIndustryInfoStocks';
import ChooseDisplyedColumnForm from './ChooseDisplyedColumnForm';
import TableIndustryInfo from './TableIndustryInfo';

export default function IndustryInfoPage(props) {
    const { industry } = useParams();
    const [showChooseDisplayedColumnForm, setShowChooseDisplayedColumnForm] = useState(false);
    const [industrySpecificData, setIndystrySpecificData] = useState(props.sectorialAnalysisData);
    const [stocksSpecificData, setStocksSpecificData] = useState(props.basicRSData); 
    const [hiddenColumns, setHiddenColumns] = useState([props.inputDataColumnNames[1], props.inputDataColumnNames[2], props.inputDataColumnNames[3], props.inputDataColumnNames[4], props.inputDataColumnNames[5], props.inputDataColumnNames[6], props.inputDataColumnNames[7], props.inputDataColumnNames[8], props.inputDataColumnNames[9], props.inputDataColumnNames[12], props.inputDataColumnNames[15], props.inputDataColumnNames[16], props.inputDataColumnNames[19], props.inputDataColumnNames[20]])

    useEffect(() => {        
        const industryData = props.sectorialAnalysisData.filter(item => (item[props.inputDataColumnNames[18]] === industry));
        setIndystrySpecificData(industryData)
      
        const stockDataWithIndustryColumn = props.basicRSData.filter(item => (item[props.inputDataColumnNames[18]] === industry));
        const stockData = stockDataWithIndustryColumn.map(({ [props.inputDataColumnNames[18]]: removedColumn, ...rest }) => rest);
        setStocksSpecificData(stockData);
    }, [props.sectorialAnalysisData, props.basicRSData, props.inputDataColumnNames, industry]);

    const handleShowChooseDisplayedColumnFormClick = () => {
        setShowChooseDisplayedColumnForm(true);
    };

    const handleCopyClick = () => {
        // Get the first column cells from the table
        const firstColumnCells = stocksSpecificData.map(obj => obj[Object.keys(obj)[0]]);;
    
        // Extract the text from each cell and join it into a single string
        const textToCopy = firstColumnCells.join(',');
    
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
    
        // Append the textarea element to the DOM and select its content
        document.body.appendChild(textarea);
        textarea.select();
    
        // Execute the copy command to copy the selected text
        document.execCommand('copy');
    
        // Remove the textarea element from the DOM
        document.body.removeChild(textarea);
    };

    return (
        <div>
            <TableIndustryInfo key="industryDataTable" data={industrySpecificData}/>
            <span
                className="copy-symbol-button"
                onClick={(e) => {
                e.stopPropagation(); // Prevent event propagation
                handleCopyClick();
                }}
                style={{marginLeft:'17px'}}
            >
                Copy Symbols
            </span> 
            <span
                className="displyed-column-button"
                onClick={(e) => {
                e.stopPropagation(); // Prevent event propagation
                handleShowChooseDisplayedColumnFormClick();
                }}
            >
                Hide/Display Data
            </span> 
            {showChooseDisplayedColumnForm && (
                <ChooseDisplyedColumnForm key="stocksDataDisplyedColumnForm" options = {Object.keys(stocksSpecificData[0])} hideColumns = {setHiddenColumns} hiddenColumns = {hiddenColumns} onClose={() => setShowChooseDisplayedColumnForm(false)} />
            )}
            <TableIndustryInfoStocks key="stocksDataTable" data={stocksSpecificData} modifyData = {setStocksSpecificData} hiddenColumns = {hiddenColumns} hideColumns = {setHiddenColumns} inputDataColumnNames = {props.inputDataColumnNames} itemsPerPage = {50}/>
        </div>
    )
}
