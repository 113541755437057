import React, {useState} from 'react'
// import { useNavigate } from 'react-router-dom';
import { TableContainer, Button } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';

export default function TableSectorialAnalysis(props) {

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // const navigate = useNavigate();
  const indexOfLastItem = currentPage * props.itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - props.itemsPerPage;
  const currentItems = props.data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(props.data.length / props.itemsPerPage);

  const handleIndustryClick = (operatingIndustry) => {
    // navigate(`/industry-info/${encodeURIComponent(operatingIndustry)}`);
    const url = `/#/industry-info/${encodeURIComponent(operatingIndustry)}`;
    window.open(url, '_blank');
  };

  const handlePageChange = (newPage) => {
    const clampedPage = Math.max(1, Math.min(newPage, totalPages)); // Ensure newPage is within valid range
    setCurrentPage(clampedPage);
  };

  const handleSort = ({ key, isNested }) => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    const sortedData = [...props.data];
  
    // const extractNumber = (str) => {
    //   const match = str.match(/-?\d+(?:\.\d+)?/); // Extract the numeric part, including decimals
    //     // return match ? parseFloat(match[0]) : 0; // Parse the extracted number as a float
    //   return match ? parseInt(match[0], 10) : 0; // Parse the extracted digits as an integer
    // };

    const sortMainRows = (mainRows) => {
      return mainRows.sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];
        // const isSpecialFormatA = /\d+(\.\d+)?\(-?\d+(\.\d+)?\)/.test(a[key]);
        // const isSpecialFormatB = /\d+(\.\d+)?\(-?\d+(\.\d+)?\)/.test(b[key]);

        // if(isSpecialFormatA && isSpecialFormatB){
        //   const formattedValueA = extractNumber(a[key]);
        //   const formattedValueB = extractNumber(b[key]);
        //   console.log(formattedValueA, formattedValueB)
        //   return newSortOrder === 'asc' ? formattedValueA - formattedValueB : formattedValueB - formattedValueA;
        // }
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return newSortOrder === 'asc' ? valueA - valueB : valueB - valueA;
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return newSortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }else if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
          return newSortOrder === 'asc' ? valueA - valueB : valueB - valueA;
        }
        return 0;
      });
    };

    sortMainRows(sortedData);  
    setSortOrder(newSortOrder);
    setSortColumn(key);
    props.modifyData(sortedData);
  }; 

  const renderArrow = (column) => {
    if (column === sortColumn) {
      const isAscending = sortOrder === 'asc';
  
      return (
        <div className="center-vertically">
          <div className={`triangle-${isAscending ? 'down' : 'up'}`}/>
        </div>
      );
    }
  
    return null;
  };

  // if data supplied by backend is null then we will display as loading
  if (props.data === null || props.data.length === 0) {
    return <div className="centered-content">
              <p>No Data</p>
           </div> ;
  }

  return (
    <div>
      <TableContainer className='table-container'>
        <table id="myTable" className="table table-bordered my-3">
          <thead style={{textAlign:'left'}} className="table-light">
            <tr>
              {currentItems.length > 0 && Object.keys(currentItems[0]).map((key, index, array) => (
                  <th onClick={() => handleSort({key})} key={key}  className={props.hiddenColumns.includes(key) ? 'hidden-column' : ''}>  
                  {key}
                  {renderArrow(key)}
                  </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                {Object.entries(item).map(([columnKey, value], cellIndex) => (
                <td
                  key={cellIndex}
                  className={props.hiddenColumns.includes(columnKey) ? 'hidden-column' : ''}
                >
                  {columnKey === props.inputDataColumnNames[18] ? (
                    <Button
                      color="primary"
                      startIcon={<BusinessIcon/>}
                      onClick={() => handleIndustryClick(value)}
                      style={{ textTransform: 'none', marginRight: '4px' }}
                    >
                      {value}
                    </Button>
                  ): (
                    value === true
                      ? 'True'
                      : value === false
                      ? 'False'
                      : value === -1
                      ? 'NA'
                      : value === null
                      ? 'NA'
                      : value
                  )}
                </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>

      {/* Render pagination buttons  */}
      <div className="text-center">
        <span
          className="pagination-arrow"
          onClick={() => handlePageChange(currentPage - 1)}
          style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
        >
          &#9664; {/* Unicode character for left arrow */}
        </span>
        <span>
          Showing {(currentPage - 1) * props.itemsPerPage + 1} - {Math.min(currentPage * props.itemsPerPage, props.data.length)} of {props.data.length}
        </span>
        <span
          className="pagination-arrow"
          onClick={() => handlePageChange(currentPage + 1)}
          style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
        >
          &#9654; {/* Unicode character for right arrow */}
        </span>
      </div>
    </div>
  )
}
