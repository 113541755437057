import React,{useState} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

export default function ChooseDisplyedColumnForm(props) {
  
    const [selectedOptions, setSelectedOptions] = useState(props.options.slice(1).filter(item => !props.hiddenColumns.includes(item)));
  
    const handleOptionChange = (event) => {
        setSelectedOptions(event.target.value)
    };
  
    const handleFormSubmit = () => {
        const columnsToBeHidden = props.options.slice(1).filter(item => !selectedOptions.includes(item));
        // console.log(props.options)
        props.onClose()
        props.hideColumns(columnsToBeHidden)
    };

    const handleFormCancel = () =>{
      props.onClose()
    };

    return (
        <Dialog open={true}>
        <DialogTitle textAlign='center'>Choose Displayed Columns</DialogTitle>
        <DialogContent>
            <FormControl fullWidth>
            <Select
                labelId="select-options-label"
                id="select-options"
                multiple
                value={selectedOptions}
                onChange={handleOptionChange}
                renderValue={(selected) => selected.join(', ')}
                style={{ height: '45px', maxWidth: '400px', borderRadius:'8px'}} 
                MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Adjust the maxHeight as needed
                      },
                    },
                  }}
            >
              {props.options.slice(1).map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={selectedOptions.includes(option)} />
                  {option}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
            <Button style={{ textAlign: 'center', borderRadius: '8px'}} onClick={handleFormCancel}>Cancel</Button>
            <Button style={{ textAlign: 'center', borderRadius: '8px'}} onClick={handleFormSubmit} variant="contained" color="primary">
            Submit
            </Button>
        </DialogActions>
        </Dialog>
    )
}
