import React from 'react'
import { Typography, TableContainer} from '@mui/material';

export default function GettingStartedBlog() {
    const tableData = [
        { id: 1, Objectives: 'Simple horizontal line setup', Category: 'Setups of interest', rowSpan:'4'},
        { id: 2, Objectives: 'Setups with favourable risk reward ratio a.k.a tight setups', Category: 'Setups of interest' },
        { id: 3, Objectives: 'Setups with accumulation in the base', Category: 'Setups of interest' },
        { id: 4, Objectives: 'IPO setups', Category: 'Setups of interest'},
        { id: 5, Objectives: 'Market health overview', Category: 'Markets and sector analysis', rowSpan:'3'},
        { id: 6, Objectives: 'Top down analysis: Identify leading sector and subsequently leading stock in that group', Category: 'Markets and sector analysis' },
        { id: 7, Objectives: 'Bottom up analysis: Given a stock, understand its sector strength and performance of peers in same sector', Category: 'Markets and sector analysis' },
        { id: 8, Objectives: 'Deciding which stocks to buy when there are multiple alerts in Watch list', Category: 'Other useful tools', rowSpan:'2' },
        { id: 9, Objectives: 'Basic filtering of stocks using RS ', Category: 'Other useful tools' },
      ];

    return (
        <div>
            <h5 className='centered' style={{ padding: '5px' }}>Getting Started</h5>
            <div style={{ marginLeft:'40px', marginRight:'40px', marginTop:'10px'}}>
                <Typography paragraph>
                    While developing this website, the author had conversations with multiple traders
                    to understand their needs. What he realized is that there is no one single universal
                    setup that everybody is interested to trade. Each trader has their own preference
                    of setups, interest to details, and different ways of analysis and filters they like
                    to play around with during the stock selection process.
                </Typography>
                <Typography paragraph>
                    Seems like one common goal of making money but with different scanning objectives,
                    right? That's correct! Here's a summary of the most preferred setups, analysis of
                    markets, and challenges of the stock selection process that surfaced in discussions
                    and are broadly categorized in appropriate buckets.
                </Typography>
            </div>
            <div className="table-wrapper" style={{ marginLeft: '180px', marginRight:'180px'}}>
                <TableContainer className='table-container'>
                    <table id="myTable" className="table table-bordered my-3">
                        <thead  className="table-light">
                            <tr>
                                <th>Objectives</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map(row => (
                            <tr key={row.id}>
                                <td style={{textAlign:'justify'}}>{row.Objectives}</td>
                                {row.rowSpan && <td rowSpan={row.rowSpan} style={{ verticalAlign: 'middle'}}>{row.Category}</td>}
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </TableContainer>
            </div>
            <div style={{ marginLeft:'40px', marginRight:'40px', marginBottom:'40px'}}>
                <Typography paragraph>
                    If you are looking to achieve one or more objectives listed above you are at right place. 
                    It was also evident that all of these analysis are distributed across the platforms which made it challenging fo trader's to smoothly follow their process.
                    Let's look at how to achieve each of the above objectives one by one through the website offerings in coming blogs.
                </Typography>
            </div>
        </div>
    )
}
