import React,{useState} from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Checkbox,
    Button,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function ConfigurationSettingFormSectorialAnalysis(props) {

    const [isMinNumberOfStocksInIndustryEnabled, setIsMinNumberOfStocksInIndustryEnabled] = useState(props.defaultValues[2]);
    const [minNumberOfStocksInIndustry, setMinNumberOfStocksInIndustry] = useState(props.defaultValues[0]);
    const [isMinGroupMarketCapEnabled, setIsMinGroupMarketCapEnabled] = useState(props.defaultValues[3]);
    const [minGroupMarketCap, setMinGroupMarketCap] = useState(props.defaultValues[1]);


    const handleMinNumberOfStocksInIndustryChange = (e) => {
        setMinNumberOfStocksInIndustry(e.target.value);
    };

    const handleMinNumberOfStocksInIndustryToggle = () => {
        setIsMinNumberOfStocksInIndustryEnabled(!isMinNumberOfStocksInIndustryEnabled);
    };

    const handleMinGroupMarketCapChange = (e) => {
        setMinGroupMarketCap(e.target.value);
    };

    const handleMinGroupMarketCapToggle = () => {
        setIsMinGroupMarketCapEnabled(!isMinGroupMarketCapEnabled);
    };

    const handleApplyClick = () => {
        props.filterData(minNumberOfStocksInIndustry, minGroupMarketCap, isMinNumberOfStocksInIndustryEnabled, isMinGroupMarketCapEnabled);
        props.setDefaultValues([minNumberOfStocksInIndustry, minGroupMarketCap, isMinNumberOfStocksInIndustryEnabled, isMinGroupMarketCapEnabled]);
        props.onClose()  
    };

    const handleResetClick = () => {
        // Add your logic for applying the selected parameters here
        setMinNumberOfStocksInIndustry(0);
        setMinGroupMarketCap(0);
        setIsMinNumberOfStocksInIndustryEnabled(false);
        setIsMinGroupMarketCapEnabled(false);
    };

    const handleCancelClick = () =>{
        props.onClose()
    };

    return (
    <Dialog open={true}>
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleCancelClick}
            style={{ position: 'absolute', top: 0, right: 15 }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle textAlign='center'>Configure Filters</DialogTitle>
        <DialogContent style={{ maxWidth: '1000px' }}>
            <div>
                <Checkbox
                    checked={isMinNumberOfStocksInIndustryEnabled}
                    onChange={handleMinNumberOfStocksInIndustryToggle}
                />
                <label>
                    Number of Stocks in Industry {'>'}
                    <input
                    type="number"
                    className="curvy-input"
                    style={{ marginLeft: '10px', width: '150px'}}
                    value={minNumberOfStocksInIndustry}
                    onChange={handleMinNumberOfStocksInIndustryChange}
                    disabled={!isMinNumberOfStocksInIndustryEnabled}
                    />
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMinGroupMarketCapEnabled}
                    onChange={handleMinGroupMarketCapToggle}
                />
                <label>
                    Group Maket Cap (Cr.) {'>'}
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={minGroupMarketCap}
                        onChange={handleMinGroupMarketCapChange}
                        disabled={!isMinGroupMarketCapEnabled}
                    />
                </label>
            </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
            <Button  style={{ textAlign: 'center', borderRadius: '8px'}} onClick={handleResetClick}>Reset Configuration</Button>
            <Button  style={{ textAlign: 'center', borderRadius: '8px'}} onClick={handleApplyClick} variant="contained" color="primary">
            Apply Configurations
            </Button>
        </DialogActions>
    </Dialog>
  )
}
