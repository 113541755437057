import './App.css';
import React, { useEffect , useState, useMemo} from 'react'
import { HashRouter, Routes, Route, Navigate} from "react-router-dom";
// import axios from 'axios';
import NavBar from './Components/NavBar';
import HorizontalSetupPage from './Components/HorizontalSetupPage';
import TightSetupPage from './Components/TightSetupPage';
import BasicRSSetupPage from './Components/BasicRSSetupPage';
import * as XLSX from 'xlsx';
import HomePage from './Components/HomePage';
import SectorialAnalysisPage from './Components/SectorialAnalysisPage';
import IndustryInfoPage from './Components/IndustryInfoPage';
import SectorGraphicalAnalysis from './Components/SectorGraphicalAnalysis';
import Watchlist from './Components/Watchlist';
import MarketBreadth from './Components/MarketBreadth';
import Login from './Components/Login';
import IPOMasterList from './Components/IPOMasterList';
import IPOAlerts from './Components/IPOAlerts';
import VolumeFootprint from './Components/VolumeFootprint';
import Blogs from './Components/Blogs';
import GettingStartedBlog from './Components/GettingStartedBlog';
import SetupsOfInterestBlog from './Components/SetupsOfInterestBlog';
import MarketAndSectorAnalysisBlog from './Components/MarketAndSectorAnalysisBlog';
import OtherUsefullToolsBlog from './Components/OtherUsefullToolsBlog';

function App() {

  const [horizontalSetupMasterData, setHorizontalSetupMasterData] = useState([])
  const [horizontalSetupDailySetupMasterData, setHorizontalSetupSetupDailySetupMasterData] = useState([])
  const [horizontalSetupLongBaseMasterData, setHorizontalSetupSetupLongBaseMasterData] = useState([])
  const [tightSetupMasterData, setTightSetupMasterData] = useState([])
  const [basicRSSetupMasterData, setBasicRSSetupMasterData] = useState([])
  const [sectorialAnalysisMasterData, setSectorialAnalysisData] = useState([])
  const [sectorialAnalysisGraphicalData, setSectorialAnalysisGraphicalData] = useState([])
  const [marketBreadthData, setMarketBreadthData] = useState([])
  const [volumeFootprintMasterData, setVolumeFootprintMasterData] = useState([])
  const [IPOData, setIPOData] = useState([])
  const [renderComonents, setRenderComonents] = useState(false);
  const [user, setUser] = useState(null);

  const inputDataColumnNames = useMemo(() =>{
    return  [ 'RS Rating',  '1 Month RS',	'3 Month RS',	'Chart Type',	'Current Closing Price(₹)',	'Above 200 Days MA',
              'Above 50 Days MA',	'Above 20 Days MA',	'Above 21 Days EMA',	'Above 10 Days EMA',	'% from 52W high',	'Last Day Rupee Volume(Cr.)',
              '30 Days Average Rupee Volume(Cr.)',	'1 Month Returns(%)',	'3 Month Returns(%)',	'1 Month High',	'3 Month High',	'Market Cap(Cr.)',
              'Industry Group',	'Float Volume(Cr.)',	'Index', '1 Day Performance(%)', '1 Week Performance(%)', '1 Month Performance(%)',
              '3 Month Performance(%)', '1 Day Performance Rank', '1 Week Rank', '1 Month Rank', '3 Month Rank', 'Number of Stocks',
              'Group Market Cap(Cr.)', 'Stock Name', 'Type of Info', '1 Week Returns(%)', 'Listing Date']
  },[])


  useEffect(() => {

    // Make an HTTP request and set the horizontal setup data in state
    // axios.get('http://127.0.0.1:5000//backendDataHorizontalSetup')
    //   .then(response => {
    //     setHorizontalSetupMasterData(response.data)
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });

    // // Make an HTTP request and set the tight setup data in state
    // axios.get('http://127.0.0.1:5000//backendDataTightSetup')
    //   .then(response => {
    //     setTightSetupMasterData(response.data)
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });

    // // Make an HTTP request and set the tight setup data in state
    // axios.get('http://127.0.0.1:5000///backendDataBasicRSSetup')
    //   .then(response => {
    //     setBasicRSSetupMasterData(response.data)
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
    const fetchData = async () => {
      try {
        const userJSON = localStorage.getItem('user');
        userJSON ? setUser(JSON.parse(userJSON)) : setUser(null);

        // Fetch data for Horizontal Setup
        const horizontalSetupResponse = await fetch('/horizontal setups.xlsx');
        const horizontalSetupBlob = await horizontalSetupResponse.blob();
        const horizontalSetupData = await readExcelData(horizontalSetupBlob);
        const dailySetup = horizontalSetupData.filter(item => item[inputDataColumnNames[3]] === 'DAILY');
        const longBase = horizontalSetupData.filter(item => item[inputDataColumnNames[3]] === 'WEEKLY');
        setHorizontalSetupMasterData(horizontalSetupData);
        setHorizontalSetupSetupDailySetupMasterData(dailySetup);
        setHorizontalSetupSetupLongBaseMasterData(longBase);

        // Fetch data for Tight Setup
        const tightSetupResponse = await fetch('/tight setups.xlsx');
        const tightSetupBlob = await tightSetupResponse.blob();
        const tightSetupData = await readExcelData(tightSetupBlob);
        setTightSetupMasterData(tightSetupData);

        // Fetch data for Basic RS Setup
        const basicRSResponse = await fetch('/RS filter.xlsx');
        const basicRSBlob = await basicRSResponse.blob();
        const basicRSData_with_listing_date = await readExcelData(basicRSBlob);
        const basicRSData = basicRSData_with_listing_date.map(({ [inputDataColumnNames[34]]: _, [inputDataColumnNames[33]]: __, ...rest }) => rest);
        setBasicRSSetupMasterData(basicRSData);
        setIPOData(basicRSData_with_listing_date);

        // Fetch data for Sectorial Analysis
        const sectorialAnalysisResponse = await fetch('/Sector Analysis.xlsx');
        const sectorialAnalysisBlob = await sectorialAnalysisResponse.blob();
        const sectorialAnalysisData = await readExcelData(sectorialAnalysisBlob);
        setSectorialAnalysisData(sectorialAnalysisData);

        // Fetch data for Sectorial Analysis Graphical Data
        const sectorialGraphicalAnalysisResponse = await fetch('/Sector Graphical Analysis.xlsx');
        const sectorialGraphicalAnalysisBlob = await sectorialGraphicalAnalysisResponse.blob();
        const sectorialGraphicalAnalysisData = await readExcelData(sectorialGraphicalAnalysisBlob);
        setSectorialAnalysisGraphicalData(sectorialGraphicalAnalysisData);

        // Fetch data for Market Breadth Data
        const marketBreadthResponse = await fetch('/Market Breadth.xlsx');
        const marketBreadthBlob = await marketBreadthResponse.blob();
        const marketBreadthData = await readExcelData(marketBreadthBlob);
        setMarketBreadthData(marketBreadthData);

        // Fetch data for Horizontal Setup
        const volumeFootprintResponse = await fetch('/volume footprint.xlsx');
        const volumeFootprintBlob = await volumeFootprintResponse.blob();
        const volumeFootprintData = await readExcelData(volumeFootprintBlob);
        setVolumeFootprintMasterData(volumeFootprintData);

        setRenderComonents(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, [inputDataColumnNames]);

  const readExcelData = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const data = reader.result;
        const workbook = XLSX.read(data, { type: 'binary' });

        // Assuming your data is in the first sheet (Sheet1)
        const sheetName = workbook.SheetNames[0];
        const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { raw: false, defval: null });

        const processedExcelData = excelData.map(row => {
          const newRow = {};
          Object.keys(row).forEach(key => {
            if(row[key] !== null){
              const trimmedValue = row[key].trim(); // Trim leading and trailing spaces
          
              // Check for the string '-1' and convert it to a number
              if (/^-?\d+(\.\d+)?$/.test(trimmedValue)) {
                newRow[key] = parseFloat(trimmedValue);
              }else {
                newRow[key] = trimmedValue; // Keep the original value if not a valid number
              }
          
              // Convert boolean strings to booleans
              if (newRow[key] === 'True' || newRow[key] === 'False') {
                newRow[key] = newRow[key] === 'True';
              }
            }
          });
          return newRow;
        });

        resolve(processedExcelData);
      };

      reader.readAsBinaryString(blob);
    });
  };

  const PrivateRoute = ({ element, fallbackPath = '/login' }) => {
    return user !== null ? element : <Navigate to={fallbackPath} replace />;
  };

  return (

    renderComonents && <HashRouter>
      <NavBar  user={user} setUser={setUser}/>

      <Routes>

          <Route path="/" element={<HomePage/>} />  

          <Route path="login" element={<Login user={user} setUser = {setUser}/>} />  

          <Route
              path="horizontal-setup/daily-setup"
              element={
                <PrivateRoute
                  element={
                    <HorizontalSetupPage key='horizontalSetupDaily' pageTitle='Horizontal Setup : Daily' data = {horizontalSetupDailySetupMasterData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="horizontal-setup/long-base"
              element={
                <PrivateRoute
                  element={
                    <HorizontalSetupPage key='horizontalSetupLongBase' pageTitle='Horizontal Setup : Long Base' data = {horizontalSetupLongBaseMasterData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />


          <Route
              path="tight-setup"
              element={
                <PrivateRoute
                  element={
                    <TightSetupPage data = {tightSetupMasterData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />


          <Route
              path="basic-rs-setup"
              element={
                <PrivateRoute
                  element={
                    <BasicRSSetupPage data = {basicRSSetupMasterData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="ipo-master-list"
              element={
                <PrivateRoute
                  element={
                    <IPOMasterList sectorialAnalysisData = {sectorialAnalysisMasterData} data = {IPOData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="ipo-alerts"
              element={
                <PrivateRoute
                  element={
                    <IPOAlerts horizontalSetupData={horizontalSetupMasterData} tightSetupData={tightSetupMasterData} sectorialAnalysisData = {sectorialAnalysisMasterData} basicRSData = {IPOData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="volume-footprint"
              element={
                <PrivateRoute
                  element={
                    <VolumeFootprint data={volumeFootprintMasterData} sectorialAnalysisData = {sectorialAnalysisMasterData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="industry-analysis"
              element={
                <PrivateRoute
                  element={
                    <SectorialAnalysisPage sectorialAnalysisData = {sectorialAnalysisMasterData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="market-breadth"
              element={
                <PrivateRoute
                  element={
                    <MarketBreadth data = {marketBreadthData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="industry-info/:industry"
              element={
                <PrivateRoute
                  element={
                    <IndustryInfoPage sectorialAnalysisData = {sectorialAnalysisMasterData} basicRSData = {basicRSSetupMasterData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="industry-graphical-view/:industries"
              element={
                <PrivateRoute
                  element={
                    <SectorGraphicalAnalysis sectorialAnalysisGraphicalData = {sectorialAnalysisGraphicalData} inputDataColumnNames = {inputDataColumnNames}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="watch-list"
              element={
                <PrivateRoute
                  element={
                    <Watchlist sectorialAnalysisData = {sectorialAnalysisMasterData} basicRSData = {basicRSSetupMasterData} inputDataColumnNames = {inputDataColumnNames} user={user} setUser = {setUser}/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="blogs"
              element={
                <PrivateRoute
                  element={
                    <Blogs/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="blogs/getting-started"
              element={
                <PrivateRoute
                  element={
                    <GettingStartedBlog/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="blogs/setup-of-interest"
              element={
                <PrivateRoute
                  element={
                    <SetupsOfInterestBlog/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="blogs/market-and-sector-analysis"
              element={
                <PrivateRoute
                  element={
                    <MarketAndSectorAnalysisBlog/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />

          <Route
              path="blogs/other-usefull-tools"
              element={
                <PrivateRoute
                  element={
                    <OtherUsefullToolsBlog/>
                  }
                  user={user}
                  fallbackPath="/login"
                />
              }
          />
      </Routes>
    </HashRouter>
    
  );
}

export default App;
