import React, {useState} from 'react'
// import { useNavigate } from 'react-router-dom';
import { TableContainer, Button } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import BusinessIcon from '@mui/icons-material/Business';

export default function Table(props) {

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // const navigate = useNavigate();

  // if data supplied by backend is null then we will display as loading
  if (props.data === null || props.data.length === 0) {
    return <div className="centered-content">
              <p>No Data</p>
            </div> ;
  }

  const indexOfLastItem = currentPage * props.itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - props.itemsPerPage;
  const currentItems = props.data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(props.data.length / props.itemsPerPage);

  const handleIndustryClick = (operatingIndustry) => {
    // navigate(`/industry-info/${encodeURIComponent(operatingIndustry)}`);    
    const url = `/#/industry-info/${encodeURIComponent(operatingIndustry)}`;
    window.open(url, '_blank');
  };

  const handlePageChange = (newPage) => {
    const clampedPage = Math.max(1, Math.min(newPage, totalPages)); // Ensure newPage is within valid range
    setCurrentPage(clampedPage);
  };

  const isValidDate = (dateString) => {
    return  /^\d{2}\/\d{2}\/\d{4}$/.test(dateString);
  };

  
  const handleSort = (Key) => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

    const sorted = [...props.data].sort((a, b) => {
      if (typeof a[Key.key] === 'number' && typeof b[Key.key] === 'number') {
        return newSortOrder === 'asc' ? a[Key.key] - b[Key.key] : b[Key.key] - a[Key.key];
      } else if (typeof a[Key.key] === 'string' && typeof b[Key.key] === 'string') {
        if (isValidDate(a[Key.key]) && isValidDate(b[Key.key])) {
          const [dayA, monthA, yearA] = a[Key.key].split('/');
          const formattedDateStringA = `${monthA}/${dayA}/${yearA}`;
          const [dayB, monthB, yearB] = b[Key.key].split('/');
          const formattedDateStringB = `${monthB}/${dayB}/${yearB}`;
          const dateA = new Date(formattedDateStringA);
          const dateB = new Date(formattedDateStringB);
          return newSortOrder=== 'asc' ? dateA - dateB : dateB - dateA;
        } else {
          return newSortOrder=== 'asc' ? a[Key.key].localeCompare(b[Key.key]) : b[Key.key].localeCompare(a[Key.key]);
        }
      }else if (typeof a[Key.key] === 'boolean' && typeof b[Key.key] === 'boolean') {
        return newSortOrder === 'asc' ? a[Key.key] - b[Key.key] : b[Key.key] - a[Key.key];
      }
      return 0; // Return 0 if no sorting criteria match
    });

    setSortOrder(newSortOrder);
    setSortColumn(Key.key);
    props.modifyData(sorted);
  };

  const renderArrow = (column) => {
    if (column === sortColumn) {
      const isAscending = sortOrder === 'asc';
  
      return (
        <div className="center-vertically">
          <div className={`triangle-${isAscending ? 'down' : 'up'}`}/>
        </div>
      );
    }
  
    return null;
  };

  const openTradingView = (stockName, chartType) => {
    const tradingViewBaseUrl = 'https://in.tradingview.com/chart/?symbol=NSE%3A';
    const interval = chartType === 'WEEKLY' ? 'W' : 'D';
    const tradingViewUrl = tradingViewBaseUrl + encodeURIComponent(stockName) + '&interval=' + interval;
    window.open(tradingViewUrl, '_blank');
  };


  return (
    <div>
      <TableContainer className='table-container'>
        <table id="myTable" className="table table-bordered my-3" >
          <thead style={{textAlign:'left'}} className="table-light">
            <tr>
              {currentItems.length > 0 && Object.keys(currentItems[0]).map((key, index, array) => (
                  <th onClick={() => handleSort({key})} key={key}  className={props.hiddenColumns.includes(key) ? 'hidden-column' : ''}>  
                  {key}
                  {renderArrow(key)}
                  </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                {Object.entries(item).map(([columnKey, value], cellIndex) => (
                <td
                  key={cellIndex}
                  className={props.hiddenColumns.includes(columnKey) ? 'hidden-column' : ''}
                >
                  {cellIndex === 0 ? (
                    <Button
                      color="primary"
                      startIcon={<LaunchIcon/>}
                      onClick={() => openTradingView(value, item[props.inputDataColumnNames[3]])}
                      style={{ textTransform: 'none', marginRight: '4px' }}
                    >
                      {value}
                    </Button>
                  ): columnKey === props.inputDataColumnNames[18] ? (
                    <Button
                      color="primary"
                      startIcon={<BusinessIcon/>}
                      onClick={() => handleIndustryClick(value)}
                      style={{ textTransform: 'none', marginRight: '4px' }}
                    >
                      {value}
                    </Button>
                  ): (
                    value === true
                      ? 'True'
                      : value === false
                      ? 'False'
                      : value === -1
                      ? 'NA'
                      : value === null
                      ? 'NA'
                      : value
                  )}
                </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>

      {/* Render pagination buttons  */}
      <div className="text-center">
        <span
          className="pagination-arrow"
          onClick={() => handlePageChange(currentPage - 1)}
          style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
        >
          &#9664; {/* Unicode character for left arrow */}
        </span>
        <span>
          Showing {(currentPage - 1) * props.itemsPerPage + 1} - {Math.min(currentPage * props.itemsPerPage, props.data.length)} of {props.data.length}
        </span>
        <span
          className="pagination-arrow"
          onClick={() => handlePageChange(currentPage + 1)}
          style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
        >
          &#9654; {/* Unicode character for right arrow */}
        </span>
      </div>
    </div>
  )
}
