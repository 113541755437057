import React, {useState, useEffect, useRef} from 'react'
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Line } from 'react-chartjs-2';
import{
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
} from 'chart.js';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
);
ChartJS.register(ChartDataLabels);

export default function MarketBreadth(props) {

  const [marketBreadthData, setMarketBreadthData] = useState(props.data);
  const lineChartRef = useRef(null);

  useEffect(() => {   
    setMarketBreadthData(props.data)
  }, [props.data]);
  
  const colors = [
    "#000000", "#FF0000", "#33a02c", "#8c564b", "#e377c2",
    "#ff7f0e", "#bcbd22", "#fb9a99", "#f58518", "#fdbf6f",
    "#76b7b2", "#cab2d6", "#7f7f7f", "#b2df8a", "#99ff99",
    "#c2f0c2", "#ffcc99", "#c2c2f0", "#ffb3e6", "#a6cee3",
    "#a6cee3", "#e31a1c",
  ];

  const getColourForLineChart = (index) => {
     return colors[index];
  }


  const getYAxisData = (data) => {
    const dataToReturn = [];
    Object.entries(data).forEach(([key, value]) => {
      if(key !== props.inputDataColumnNames[32]){
        dataToReturn.push(value);
      }
    });
    return dataToReturn
  }

  const getXAxisData = (data) => {
    if (data !== undefined) {
      const dataToReturn = [];
      let labelCounter = 0;

      Object.entries(data).forEach(([key, value]) => {
        if(key !== props.inputDataColumnNames[32]){
          const fullDate = new Date(key);
          const day = fullDate.getDate();
          const formattedDate = fullDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
          
          if (labelCounter % 7 === 0) {
            dataToReturn.push(formattedDate);
          } else {
            dataToReturn.push(day);
          }
          labelCounter++;
        }
      });
      dataToReturn.push('');
      dataToReturn.push('');
      return dataToReturn
    }
  }

  const datasets = marketBreadthData.map((MAInfo, index) => ({
    label: MAInfo[props.inputDataColumnNames[32]],
    backgroundColor: getColourForLineChart(index),
    borderColor: getColourForLineChart(index),
    borderWidth: 1.8,
    hoverBackgroundColor: getColourForLineChart(index),
    hoverBorderColor: getColourForLineChart(index),
    data: getYAxisData(MAInfo),
  }));

  const initialHiddenState = Array(datasets.length).fill(false);

  const data = {
    labels: getXAxisData(marketBreadthData[0]),
    datasets,
  };

  const legendClickHandler = (event, elements) => {
    if (elements.length > 0) {
      const clickedIndex = elements[0].datasetIndex;
      const newHiddenState = initialHiddenState.map((_, index) => index !== clickedIndex);
      data.datasets.forEach((dataset, index) => {
        dataset.hidden = newHiddenState[index];
      });
      lineChartRef.current.chartInstance.update();
    }
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'start',
        labels: {
          font: {
            size: 16
          }
        }
      },
      datalabels: {
        anchor: 'end',
        align:'right',
        offset:5,
        formatter: (value, context) => {
          if (context.dataIndex === context.dataset.data.length - 1) {
            return parseInt(value)
          } else {
            return '';
          }
        },
        color: (context) => {
          return context.dataset.borderColor;
        },
      }
    },  
    onClick: legendClickHandler,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          font: {
            size: 16, // Adjusted axis label font size
            weight: 'bold'
          }
        },
        grid: {
          display: true,
          drawOnChartArea: false 
        }
      },
      y: {
        title: {
          display: true,
          text: 'Number of Stocks',
          font: {
            size: 16,
            weight: 'bold',
          }
        },
      }
    },
    elements: {
      point: {
        radius: 0, // Set pointRadius to 0 to hide dots
        hitRadius: 0,
      },
    },
  };

  return (
    <div>
      <h5 className='centered' style={{ padding: '5px' }}>Market Breadth</h5>
      {/* <div style={{ marginLeft: '10px'}}>
        <p style={{ fontSize: '15px' }}>
          <strong>Notes:</strong>
        </p>
        <ul>
          <li>Only those industries with stocks greater than 45 are shown below</li>
          <li>The graph only displays industry movement over last 3 month (roughly 60 trading days) starting from zero reference for all industries</li>
          <li>You can choose to view/Hide the plot for a speciifc industry by clicking on the legend</li>
        </ul>
      </div> */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', padding:'10px'}}>
        <div style={{width: '100%', height: '100%', justifyContent: 'center'}}>
          <Line ref={lineChartRef} data={data} options={options} />
        </div>
      </div>
    </div>
  );
}
