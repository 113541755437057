import React,{useState} from 'react'
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { useNavigate} from 'react-router-dom';
import LoggedInUserDetailsDialog from './LoggedInUserDetailsDialog';


export default function NavBar(props) {

  const navigate = useNavigate();
  const [isUserDetailsDialogOpen, setIsUserDetailsDialogOpen] = useState(false);
  const [avatarComponent, setAvatarComponent] = useState(null);

  const handleSignInButtonClick = () => {
    navigate('/login');
  };

  const handleAvatarClick = (event) => {
    event.stopPropagation();
    setIsUserDetailsDialogOpen(true);
    setAvatarComponent(event.currentTarget);
  };

  const handleCloseUserDetailsDialog = () => {
    setIsUserDetailsDialogOpen(false);
    // console.log(isUserDetailsDialogOpen);
  };

  return (
    <div>
    <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{ borderRadius: '10px' }}>
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 text-center">
            <li className="nav-item">
              <Link className="nav-link active" style={{ borderRadius: '10px'}} to="/">Home</Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" style={{ borderRadius: '10px' }} to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Scanners
              </Link>
              <ul className="dropdown-menu text-start">
                <li><Link className="dropdown-item" to="/basic-rs-setup">RS Scanner</Link></li>
                <li className="nav-item dropend">
                  <Link className="nav-link dropdown-toggle" style={{ borderRadius: '10px', marginLeft:'9px'}} to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Horizontal Setup
                  </Link>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/horizontal-setup/daily-setup">Daily Setup</Link></li>
                    <li><Link className="dropdown-item" to="/horizontal-setup/long-base">Long Base</Link></li>
                  </ul>
                </li>
                <li><Link className="dropdown-item" to="/tight-setup">Tight Setup</Link></li>
                <li><Link className="dropdown-item" to="/volume-footprint">Volume Footprint</Link></li>
                <li className="nav-item dropend">
                  <Link className="nav-link dropdown-toggle" style={{ borderRadius: '10px', marginLeft:'9px'}} to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  IPO Scanner
                  </Link>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/ipo-master-list">Master List</Link></li>
                    <li><Link className="dropdown-item" to="/ipo-alerts">Alerts</Link></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" style={{ borderRadius: '10px' }} to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Market Analysis
              </Link>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/industry-analysis">Industry Analysis</Link></li>
                <li><Link className="dropdown-item" to="/market-breadth">Market Breadth</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" style={{ borderRadius: '10px'}} to="/watch-list">Create Watchlist</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" style={{ borderRadius: '10px'}} to="/blogs">Blogs</Link>
            </li>
          </ul>
          {/* <ul className="navbar-nav">
            <li className="nav-item">
              <span className="nav-link active" style={{ borderRadius: '10px'}}>
                <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '5px' }} />
                techscannerpro@gmail.com
              </span>
            </li>
          </ul> */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <div className="user-profile">
                {props.user ? (
                  <>
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        cursor: 'pointer',
                        backgroundColor: '#008080', 
                        color: '#ecf0f1', 
                        fontSize: 18,
                        
                      }}
                      onClick={handleAvatarClick}
                    >
                      {props.user.displayName.charAt(0).toUpperCase()}
                    </Avatar>
                  </>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleSignInButtonClick}>
                    Sign In
                  </Button>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    {isUserDetailsDialogOpen && (
      <LoggedInUserDetailsDialog user={props.user} setUser={props.setUser} open={isUserDetailsDialogOpen} onClose={handleCloseUserDetailsDialog} anchorEl={avatarComponent}/>
    )}
    </div>
  )
}
