import React,{useState, useEffect} from 'react'
import AddingStockInWatchlistForm from './AddingStockInWatchlistForm';
import TableWatchlist from './TableWatchlist';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import SaveWatchlistForm from './SaveWatchlistForm';
import { Select, MenuItem } from '@mui/material';
import {db} from '../Firebase'
import {  doc, onSnapshot} from 'firebase/firestore';
import DeleteWatchlistForm from './DeleteWatchlistForm';

export default function Watchlist(props) {

  const [watchListTableData, setWatchListTableData] = useState([]);
  const [totalWatchListData, setTotalWatchListData] = useState(null);
  const hiddenColumns = [props.inputDataColumnNames[1], props.inputDataColumnNames[2], props.inputDataColumnNames[3], props.inputDataColumnNames[4], props.inputDataColumnNames[5], props.inputDataColumnNames[6], props.inputDataColumnNames[7], props.inputDataColumnNames[8], props.inputDataColumnNames[9], props.inputDataColumnNames[10], props.inputDataColumnNames[12], props.inputDataColumnNames[13], props.inputDataColumnNames[14], props.inputDataColumnNames[15], props.inputDataColumnNames[16], props.inputDataColumnNames[17], props.inputDataColumnNames[19], props.inputDataColumnNames[20], props.inputDataColumnNames[21], props.inputDataColumnNames[22], props.inputDataColumnNames[23], props.inputDataColumnNames[24], props.inputDataColumnNames[25], props.inputDataColumnNames[29], props.inputDataColumnNames[30], props.inputDataColumnNames[33]]
  const [showAddingStockInWatchlistForm, setShowAddingStockInWatchlistForm] = useState(false);
  const [showSaveWatchlistForm, setSaveWatchlistForm] = useState(false);
  const [showDeleteWatchlistForm, setDeleteWatchlistForm] = useState(false);
  const [watchlistsForSelection, setWatchlistsForSelection] = useState(undefined);
  const [selectedWatchlist, setSelectedWatchlist] = useState('');

  useEffect(() => {        
    const sectorDataDictionary = {};
    props.sectorialAnalysisData.forEach(item => {
        sectorDataDictionary[item[props.inputDataColumnNames[18]]] = item;
    });

    const mergedData = props.basicRSData.map(item => {
      const matchingItem = sectorDataDictionary[item[props.inputDataColumnNames[18]]];
      return { ...item, ...matchingItem };
    });
    setTotalWatchListData(mergedData)
  }, [props.sectorialAnalysisData, props.basicRSData, props.inputDataColumnNames]);

  useEffect(() => {
    const userDocRef = doc(db, 'users', props.user.email);
    const unsubscribe = onSnapshot(userDocRef, (userDocSnapshot) => {
      const userData = userDocSnapshot.data();
      const userWatchlists = userData ? userData.Watchlists : [];
      setWatchlistsForSelection(userWatchlists);
    });

    return () => {
      unsubscribe();
    };
  }, [props.user]);

  const handleShowAddingStockInWatchlistFormClick = () => {
      setShowAddingStockInWatchlistForm(true);
  };

  const handleSaveClick = () => {
      setSaveWatchlistForm(true);
  };

  const handleDeleteClick = () => {
    setDeleteWatchlistForm(true);
  };

  const handleSelectedWatchlistChange = (event) => {
    const newSelectedWatchlistId = event.target.value;
    setSelectedWatchlist(newSelectedWatchlistId);

    const selectedWatchlistdata = watchlistsForSelection[newSelectedWatchlistId];
    const stocksForAditionInWatchlist = Object.values(selectedWatchlistdata);
    const filteredData = totalWatchListData.filter((item) =>
        stocksForAditionInWatchlist.includes(item[props.inputDataColumnNames[31]])
    );
    setWatchListTableData(filteredData);
  };

  return (
    <div>
        <h5 className='centered' style={{ padding: '5px' }}>Watch List</h5>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <div>
                <span
                    className="generic-button"
                    onClick={(e) => {
                    e.stopPropagation(); // Prevent event propagation
                    handleShowAddingStockInWatchlistFormClick();
                    }}
                    style={{marginLeft:'17px'}}
                >
                    Add Stocks
                </span> 
                <span
                    className="generic-button"
                    onClick={(e) => {
                    e.stopPropagation(); // Prevent event propagation
                    handleSaveClick();
                    }}
                    style={{marginLeft:'5px'}}
                >
                    Save Watchlist
                </span>   
                <span
                    className="generic-button"
                    onClick={(e) => {
                    e.stopPropagation(); // Prevent event propagation
                    handleDeleteClick();
                    }}
                    style={{marginLeft:'5px'}}
                >
                    Delete Watchlists
                </span> 
            </div>
            {watchlistsForSelection && Object.keys(watchlistsForSelection).length > 0 && <div>
                <label>
                    Select Watchlist:
                    <Select
                        style={{ height: '45px', marginLeft: '8px', borderRadius:'8px'}}
                        value={selectedWatchlist}
                        onChange={handleSelectedWatchlistChange}
                    >
                        {Object.entries(watchlistsForSelection).map(([watchlistName, watchlistData]) => (
                            <MenuItem key={watchlistName} value={watchlistName}>
                                {watchlistName}
                            </MenuItem>
                        ))}
                    </Select>
                </label>
            </div>}
        </div> 
        {showAddingStockInWatchlistForm && (
            <AddingStockInWatchlistForm data={totalWatchListData} addSelectedStockInWatchlist={setWatchListTableData} inputDataColumnNames = {props.inputDataColumnNames} defaultValues = {watchListTableData.map(obj => obj[props.inputDataColumnNames[31]])} onClose={() => setShowAddingStockInWatchlistForm(false)} />
        )}
        {showSaveWatchlistForm && (
            <SaveWatchlistForm data={watchListTableData.map(obj => obj[props.inputDataColumnNames[31]])} user={props.user} selectedWatchlistName={selectedWatchlist} onClose={() => setSaveWatchlistForm(false)}/>
        )}
        {showDeleteWatchlistForm && (
            <DeleteWatchlistForm watchlists={watchlistsForSelection} user={props.user} onClose={() => setDeleteWatchlistForm(false)}/>
        )}
        <TableWatchlist key="watchlistTable" data={watchListTableData} modifyData = {setWatchListTableData} hiddenColumns = {hiddenColumns} inputDataColumnNames = {props.inputDataColumnNames} itemsPerPage = {50}/>
        <p style={{ fontSize: '18px', marginLeft:'17px' }}><strong>Note:</strong></p>
        <ol style={{marginLeft:'17px' }}>
          <li>1 Week, 1 Month, 3 Month ranks are industry performance ranks</li>
          <li>Click on <FaFileInvoiceDollar/> to view fundamentals</li>
        </ol>
    </div>
  )
}
