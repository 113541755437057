import React,{useState, useEffect} from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    TableContainer,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function SummaryForm(props) {

    const [summaryData, setSummaryData] = useState([])
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortColumn, setSortColumn] = useState(null);

    useEffect(() => { 
        const findUniqueIndustriesAndCorrespondingShareCount = () => {
            const industryStocksCount = {};
    
            props.data.forEach((stock) => {
                const industry = stock[props.inputDataColumnNames[18]];
                industryStocksCount[industry] = (industryStocksCount[industry] || 0) + 1;
            });
    
            const result = Object.keys(industryStocksCount).map((industry) => ({
                'Industry Group':industry,
                'Number of Stocks': industryStocksCount[industry],
            }));

            result.sort((a, b) => b['Number of Stocks'] - a['Number of Stocks']);
            setSummaryData(result);
        };

        findUniqueIndustriesAndCorrespondingShareCount();
    }, [props.data, props.inputDataColumnNames]);
  
    const handleSort = (Key) => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    
        const sorted = [...summaryData].sort((a, b) => {
          if (typeof a[Key.key] === 'number' && typeof b[Key.key] === 'number') {
            return newSortOrder === 'asc' ? a[Key.key] - b[Key.key] : b[Key.key] - a[Key.key];
          } else if (typeof a[Key.key] === 'string' && typeof b[Key.key] === 'string') {
            return newSortOrder === 'asc' ? a[Key.key].localeCompare(b[Key.key]) : b[Key.key].localeCompare(a[Key.key]);
          }
          return 0; // Return 0 if no sorting criteria match
        });
    
        setSortOrder(newSortOrder);
        setSortColumn(Key.key);
        setSummaryData(sorted)
    };

    const renderArrow = (column) => {
        if (column === sortColumn) {
          const isAscending = sortOrder === 'asc';
      
          return (
            <div className="center-vertically">
              <div className={`triangle-${isAscending ? 'down' : 'up'}`}/>
            </div>
          );
        }
      
        return null;
      };

      
    if (props.data === null || props.data.length === 0) {
        return <div className="centered-content">
                  <p>No Data</p>
               </div> ;
    }

    const handleCancelClick = () =>{
        props.onClose()
    };


    return (
        <Dialog open={true}>
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleCancelClick}
            style={{ position: 'absolute', top: 0, right: 15 }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle textAlign='center'>Setup Summary</DialogTitle>
        <DialogContent style={{ maxWidth: '1000px' }}>
            <TableContainer className='table-container'>
                <table id="myTable" className="table table-bordered my-3">
                <thead style={{textAlign:'left'}} className="table-light">
                    <tr>
                        {summaryData.length > 0 && Object.keys(summaryData[0]).map((key, index, array) => (
                            <th onClick={() => handleSort({key})} key={key}>  
                                {key}
                                {renderArrow(key)}
                            </th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                    {summaryData.map((item, index) => (
                    <tr key={index}>
                        {Object.entries(item).map(([columnKey, value], cellIndex) => (
                        <td
                        key={cellIndex}
                        >
                        {
                            value === true
                            ? 'True'
                            : value === false
                            ? 'False'
                            : value === -1
                            ? 'NA'
                            : value === null
                            ? 'NA'
                            : value
                        }
                        </td>
                        ))}
                    </tr>
                    ))}
                </tbody>
                </table>
            </TableContainer>
        </DialogContent>
        </Dialog>

    )
}
