import React from 'react'
import { Typography} from '@mui/material';
import { Link } from 'react-router-dom';

export default function MarketAndSectorAnalysisBlog() {
  return (
    <div>
        <h5 className='centered' style={{ padding: '5px' }}>Market and Sector Analysis</h5>
        <div style={{ marginLeft:'40px', marginRight:'40px', marginTop:'10px'}}>
            <Typography paragraph>
                Trading works better when market is healthy, and works even better if the stock being traded is in a strong sector and industry. 
                Although this is easy to comprehend, difficult to put it in practice. Because, clearly traders posed below challenges faced in achieving the above,
            </Typography>
            <ol style={{marginLeft:'17px'}}>
                <li>
                    Identifying leading sectors, industries in the overall market
                </li>
                <li>
                    For any stock, check if it's in a strong sector, and compare it with similar stocks to see if it's a leader.
                </li>
            </ol> 
            <Typography paragraph>
                We tried to address the above issues with some number crunching as discussed below.
            </Typography>
            <Typography variant="h7" style={{marginBottom:'10px'}} component="div">
              <strong>Top Down Approach :- Identifying leading industry followed by leading stocks</strong>
            </Typography>
            <Typography paragraph>
                Top-down analysis begins by evaluating market sectors/industries, identifying strong groups, and exploring the stocks driving industry movements. 
                {'\t'}<Link to="/industry-analysis">Industry Analysis</Link> tab in Market Analysis sorts industries by performance across various time scales(1D, 1W, 1M, 3M) offering a broad view of sector and industry strength.
                Click on an industry to further check and sort individual stocks based on criteria like Relative Strength (RS) and 1-month returns.
            </Typography>
            <Typography paragraph>
                If the industry rank is good across all time scales, one can conclude that the industry is trending.
            </Typography>
            <Typography variant="h7" style={{marginBottom:'10px'}} component="div">
              <strong>Show Graphical View :-</strong>
            </Typography>
            <Typography paragraph>
                This gives a pictorial overview of industry trends in past 3 months of industry groups with large number of stocks. 
            </Typography>
            <img style={{maxWidth: '80%', height: 'auto', marginBottom:'15px', marginLeft:'120px'}} src="/ShowGraphicalView.png" alt="Show Graphical View" />
            <Typography paragraph>
                <strong>Note:</strong> The tables and graph in the pages indicates the past performance of industry and isn't a measure of its future. 
                While the time period for which an industry dominates in a market is dependent on so many other factors, it's better to do your own due diligence. 
            </Typography>
            <Typography variant="h7" style={{marginBottom:'10px'}} component="div">
              <strong>Bottom Up Approach :- For a stock, understand its sector strength, and compare with its peers</strong>
            </Typography>
            <Typography paragraph>
                Prior to entering a trade, it's crucial to understand that the selected stock belongs to a robust industry, and further check other stocks in group to make sure you're hopping on to best stock in group. 
                You can confirm this by studying its industry rank. This can be achieved at various sections of website as follows,
            </Typography>
            <ol style={{marginLeft:'17px'}}>
                <li>
                    Searching the stock in <Link to="/basic-rs-setup">RS Scanner</Link>
                </li>
                <li>
                    If you've identified the stock using the <Link to="/horizontal-setup/daily-setup">Horizontal Setup</Link> and <Link to="/tight-setup">Tight Setup Scanner</Link>, promptly click on the industry column and proceed to the redirected page
                </li>
            </ol> 
            <Typography variant="h7" style={{marginBottom:'10px'}} component="div">
              <strong>Bonus :- Market breadth</strong>
            </Typography>
            <Typography paragraph>
                The market breadth indicator reveals the count of stocks above their 20-day, 50-day, and 200-day moving averages.
                While many sources present this data in Excel format, we provide a visual representation for users, making it easier to comprehend at a glance.
            </Typography>
            <img style={{maxWidth: '80%', height: 'auto', marginTop: '15px', marginBottom:'15px', marginLeft:'120px'}} src="/marketBreadth.png" alt="Market Breadth" />
        </div>
    </div>
  )
}
