import React, {useState} from 'react'
// import { useNavigate } from 'react-router-dom';
import { TableContainer, Button } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import BusinessIcon from '@mui/icons-material/Business';
import { FaFileInvoiceDollar } from 'react-icons/fa';

export default function TableWatchlist(props) {

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  // const navigate = useNavigate();

  // if data supplied by backend is null then we will display as loading
  if (props.data === null || props.data.length === 0) {
    return <div className="centered-content">
              <p>No Data</p>
            </div> ;
  }

  const indexOfLastItem = currentPage * props.itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - props.itemsPerPage;
  const currentItems = props.data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(props.data.length / props.itemsPerPage);

  const handleIndustryClick = (operatingIndustry) => {
    // navigate(`/industry-info/${encodeURIComponent(operatingIndustry)}`);    
    const url = `/#/industry-info/${encodeURIComponent(operatingIndustry)}`;
    window.open(url, '_blank');
  };

  const handlePageChange = (newPage) => {
    const clampedPage = Math.max(1, Math.min(newPage, totalPages)); // Ensure newPage is within valid range
    setCurrentPage(clampedPage);
  };

  const handleSort = (Key) => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

    const sorted = [...props.data].sort((a, b) => {
      if (typeof a[Key.key] === 'number' && typeof b[Key.key] === 'number') {
        return newSortOrder === 'asc' ? a[Key.key] - b[Key.key] : b[Key.key] - a[Key.key];
      } else if (typeof a[Key.key] === 'string' && typeof b[Key.key] === 'string') {
        return newSortOrder === 'asc' ? a[Key.key].localeCompare(b[Key.key]) : b[Key.key].localeCompare(a[Key.key]);
      }else if (typeof a[Key.key] === 'boolean' && typeof b[Key.key] === 'boolean') {
        return newSortOrder === 'asc' ? a[Key.key] - b[Key.key] : b[Key.key] - a[Key.key];
      }
      
      return 0; // Return 0 if no sorting criteria match
    });

    setSortOrder(newSortOrder);
    setSortColumn(Key.key);
    props.modifyData(sorted)
  };

  const renderArrow = (column) => {
    if (column === sortColumn) {
      const isAscending = sortOrder === 'asc';
  
      return (
        <div className="center-vertically">
          <div className={`triangle-${isAscending ? 'down' : 'up'}`}/>
        </div>
      );
    }
  
    return null;
  };

  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  const handleRowLeave = () => {
    setHoveredRowIndex(null);
  };

  const openTradingView = (stockName, chartType) => {
    const tradingViewBaseUrl = 'https://in.tradingview.com/chart/?symbol=NSE%3A';
    const interval = chartType === 'WEEKLY' ? 'W' : 'D';
    const tradingViewUrl = tradingViewBaseUrl + encodeURIComponent(stockName) + '&interval=' + interval;
    window.open(tradingViewUrl, '_blank');
  };

  const openScreener = (stockName) => {
    const screenerBaseUrl = 'https://www.screener.in/company/';
    const screenerUrl = screenerBaseUrl + encodeURIComponent(stockName) + '/consolidated/';
    window.open(screenerUrl, '_blank');
  };

  const handleDeleteRowClick = (rowIndex) => {
    const updatedData = [...props.data];
    updatedData.splice(indexOfFirstItem + rowIndex, 1);
    props.modifyData(updatedData);

    if (currentPage > 1 && currentItems.length === 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  return (
    <div>
      <TableContainer className='table-container'>
        <table id="myTable" className="table table-bordered my-3" >
          <thead style={{textAlign:'left'}} className="table-light">
            <tr>
              {currentItems.length > 0 && Object.keys(currentItems[0]).map((key, index, array) => (
                  <th onClick={() => handleSort({key})} key={key}  className={props.hiddenColumns.includes(key) ? 'hidden-column' : ''}>  
                  {key}
                  {renderArrow(key)}
                  </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr
                key={index}
                onMouseEnter={() => handleRowHover(index)}
                onMouseLeave={handleRowLeave}
              >
                {Object.entries(item).map(([columnKey, value], cellIndex) => (
                <td
                  key={cellIndex}
                  className={props.hiddenColumns.includes(columnKey) ? 'hidden-column' : ''}
                >
                  {cellIndex === 0 ? (
                    <div>
                      <Button
                        color="primary"
                        startIcon={<LaunchIcon/>}
                        onClick={() => openTradingView(value, item[props.inputDataColumnNames[3]])}
                        style={{ textTransform: 'none', marginRight: '4px' }}
                      >
                        {value}
                      </Button>
                      <span
                          style={{
                            marginLeft: 7,
                            fontSize: 18,
                            cursor: 'pointer',
                            display: 'inline',
                          }}
                          onClick={() => openScreener(value)}
                      >
                      <FaFileInvoiceDollar/>
                      </span>
                    </div>
                  ): columnKey === props.inputDataColumnNames[18] ? (
                    <Button
                      color="primary"
                      startIcon={<BusinessIcon/>}
                      onClick={() => handleIndustryClick(value)}
                      style={{ textTransform: 'none', marginRight: '4px' }}
                    >
                      {value}
                    </Button>
                  ): columnKey === props.inputDataColumnNames[28] ? (
                    <div>
                      <span>{value}</span>
                      {hoveredRowIndex === index && (
                        <span
                          style={{
                            marginLeft: 25,
                            fontSize: 18,
                            cursor: 'pointer',
                            display: 'inline',
                          }}
                          onClick={() => handleDeleteRowClick(index)}
                        >❌</span>
                      )}
                    </div>
                  ): (
                    value === true
                      ? 'True'
                      : value === false
                      ? 'False'
                      : value === -1
                      ? 'NA'
                      : value === null
                      ? 'NA'
                      : value
                  )}
                </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>

      {/* Render pagination buttons  */}
      <div className="text-center">
        <span
          className="pagination-arrow"
          onClick={() => handlePageChange(currentPage - 1)}
          style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
        >
          &#9664; {/* Unicode character for left arrow */}
        </span>
        <span>
          Showing {(currentPage - 1) * props.itemsPerPage + 1} - {Math.min(currentPage * props.itemsPerPage, props.data.length)} of {props.data.length}
        </span>
        <span
          className="pagination-arrow"
          onClick={() => handlePageChange(currentPage + 1)}
          style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
        >
          &#9654; {/* Unicode character for right arrow */}
        </span>
      </div>
    </div>
  )
}
