import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function HomePage() {

  const containerStyle = {
    backgroundImage: 'url("/homePage.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh', 
    width: '100%',
    color: 'white',
  };

  
  return (
    <div style={containerStyle}>
      <h2 className='centered' style={{ padding: '20px'}}>Technical Chart Filters</h2>
      <div style={{marginLeft:'20px'}}>
        <h3>Highlights:</h3>
        <ul style={{fontSize:'21px'}}>
          <li>3 custom scanners for detecting most desired setups and a RS rating filter</li>
          <li>First ever horizontal resistance line scanner and base accumulation scanner</li>
          <li>Market breadth and industry wise ranking for analysing sector strength</li>
          <li>Hassle free transfer of names to trading view in a single click</li>
          <li>Visit <Link to="/blogs">Blogs</Link> to learn more</li>
        </ul>
      </div>
      <h4 className='centered' style={{ padding: '20px', position:'fixed', bottom:'0', left:'50%', transform: 'translateX(-50%)'}}>
        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '5px'}} />
        <a href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=techscannerpro@gmail.com" target="_blank" rel="noopener noreferrer">techscannerpro@gmail.com</a>
      </h4>
    </div>
  );
}
