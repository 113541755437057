import React from 'react'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';

export default function DownloadButton(props) {

    const download_csv = () => {

        // const symbolData = props.data.map(item => Object.values(item)[0]);

        const filteredData = props.data.map(item => {
            const filteredItem = {};
        
            // Exclude hidden columns from the item
            Object.entries(item).forEach(([column, value]) => {
              if (!props.hiddenColumns.includes(column)) {
                filteredItem[column] = value;
              }
            });
        
            return filteredItem;
          });
        
        // Convert filtered data to CSV
        const csv = Papa.unparse(filteredData);
    
        // Create a Blob containing the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    
        // Use the FileSaver library to trigger the download
        saveAs(blob, props.downloadFilename + ".csv");
    }

    return (  
        <div className="text-center">      
            <Button style={{ textAlign: 'center', borderRadius: '8px', margin:'8px'}} onClick={download_csv} variant="contained" color="primary">
            {props.text}
            </Button>
        </div>   
    )
}
