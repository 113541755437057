import React,{useState} from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    Checkbox,
    Button,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function ConfigurationSettingFormBasicRSSetup(props) {
    const [isMAsEnabled, setIsMAsEnabled] = useState(props.defaultValues[15]);
    const [selectedMAs, setSelectedMAs] = useState(props.defaultValues[0]);
    const [isMaxDistanceEnabled, setIsMaxDistanceEnabled] = useState(props.defaultValues[16]);
    const [maxDistance, setMaxDistance] = useState(props.defaultValues[1]);
    const [isMinOverallRSEnabled, setIsMinOverallRSEnabled] = useState(props.defaultValues[17]);
    const [minOverallRSRating, setMinOverallRSRating] = useState(props.defaultValues[2]);
    const [isMinRupeeVolumeLastDayEnabled, setIsMinRupeeVolumeLastDayEnabled] = useState(props.defaultValues[18]);
    const [minRupeeVolumeLastDay, setMinRupeeVolumeLastDay] = useState(props.defaultValues[3]);
    const [isMinAvgRupeeVolume30DaysEnabled, setIsMinAvgRupeeVolume30DaysEnabled] = useState(props.defaultValues[19]);
    const [minAvgRupeeVolume30Days, setMinAvgRupeeVolume30Days] = useState(props.defaultValues[4]);
    const [isMinMarketCapEnabled, setIsMinMarketCapEnabled] = useState(props.defaultValues[20]);
    const [minMarketCap, setMinMarketCap] = useState(props.defaultValues[5]);
    const [isMinFloatVolumeEnabled, setIsMinFloatVolumeEnabled] = useState(props.defaultValues[21]);
    const [minFloatVolume, setMinFloatVolume] = useState(props.defaultValues[6]);
    const [isChartTypesEnabled, setIsChartTypesEnabled] = useState(props.defaultValues[22]);  
    const [chartTypes, setChartTypes] = useState(props.defaultValues[7]);
    const [isHighBooleanEnabled, setIsHighBooleanEnabled] = useState(props.defaultValues[23]);
    const [selectedHighBooleans, setSelectedHighBooleans] = useState(props.defaultValues[8]);
    const [isMin1MonthRSEnabled, setIsMin1MonthRSEnabled] = useState(props.defaultValues[24]);
    const [min1MonthRSRating, setMinn1MonthRSRating] = useState(props.defaultValues[9]);
    const [isMin3MonthRSEnabled, setIsMin3MonthRSEnabled] = useState(props.defaultValues[25]);
    const [min3MonthRSRating, setMin3MonthRSRating] = useState(props.defaultValues[10]);
    const [isMin1MonthReturnEnabled, setIsMin1MonthReturnEnabled] = useState(props.defaultValues[26]);
    const [min1MonthReturn, setMin1MonthReturn] = useState(props.defaultValues[11]);
    const [isMin3MonthReturnEnabled, setIsMin3MonthReturnEnabled] = useState(props.defaultValues[27]);
    const [min3MonthReturn, setMin3MonthReturn] = useState(props.defaultValues[12]);
    const [isMinClosingPriceEnabled, setIsMinClosingPriceEnabled] = useState(props.defaultValues[28]);
    const [minClosingPrice, setMinClosingPrice] = useState(props.defaultValues[13]);
    const [isOperatingIndustryEnabled, setIsOperatingIndustryEnabled] = useState(props.defaultValues[29]);  
    const [operatingIndustry, setOperatingIndustry] = useState(props.defaultValues[14]);

    const handleMAOptionChange = (event) => {
        setSelectedMAs(event.target.value)
    };

    const handleMAsToggle = () => {
        setIsMAsEnabled(!isMAsEnabled);
    };

    const handleMaxDistanceChange = (e) => {
        setMaxDistance(e.target.value);
    };

    const handleMaxDistanceToggle = () => {
        setIsMaxDistanceEnabled(!isMaxDistanceEnabled);
    };

    const handleMinOverallRSRating = (e) => {
        setMinOverallRSRating(e.target.value);
    };

    const handleMinOverallRSToggle = () => {
        setIsMinOverallRSEnabled(!isMinOverallRSEnabled);
    };

    const handleMinRupeeVolumeLastDayChange = (e) => {
        setMinRupeeVolumeLastDay(e.target.value);
    };

    const handleMinRupeeVolumeLastDayToggle = () => {
        setIsMinRupeeVolumeLastDayEnabled(!isMinRupeeVolumeLastDayEnabled);
    };

    const handleMinRupeeVolume30DaysAvgChange = (e) => {
        setMinAvgRupeeVolume30Days(e.target.value);
    };

    const handleMinAvgRupeeVolume30DaysToggle = () => {
        setIsMinAvgRupeeVolume30DaysEnabled(!isMinAvgRupeeVolume30DaysEnabled);
    };

    const handleMinMarketCapChange = (e) => {
        setMinMarketCap(e.target.value);
    };

    const handleMinMarketCapToggle = () => {
        setIsMinMarketCapEnabled(!isMinMarketCapEnabled);
    };

    const handleMinFloatVolumeChange = (e) => {
        setMinFloatVolume(e.target.value);
    };

    const handleMinFloatVolumeToggle = () => {
        setIsMinFloatVolumeEnabled(!isMinFloatVolumeEnabled);
    };

    const handleChartTypeOptionChange = (event) => {
        setChartTypes(event.target.value)
    };

    const handleChartTypesToggle = () => {
        setIsChartTypesEnabled(!isChartTypesEnabled);
    };

    const handleHighBooleanOptionChange = (event) => {
        setSelectedHighBooleans(event.target.value)
    };

    const handleHighBooleanToggle = () => {
        setIsHighBooleanEnabled(!isHighBooleanEnabled);
    };    

    const handleMin1MonthRSRatingChange = (e) => {
        setMinn1MonthRSRating(e.target.value);
    };

    const handleMin1MonthRSToggle = () => {
        setIsMin1MonthRSEnabled(!isMin1MonthRSEnabled);
    };  

    const handleMin3MonthRSRatingChange = (e) => {
        setMin3MonthRSRating(e.target.value);
    };

    const handleMin3MonthRSToggle = () => {
        setIsMin3MonthRSEnabled(!isMin3MonthReturnEnabled);
    };  

    const handleMin1MonthReturnChange = (e) => {
        setMin1MonthReturn(e.target.value);
    };

    const handleMin1MonthReturnToggle = () => {
        setIsMin1MonthReturnEnabled(!isMin1MonthReturnEnabled);
    };  

    const handleMin3MonthReturnChange = (e) => {
        setMin3MonthReturn(e.target.value);
    };

    const handleMin3MonthReturnToggle = () => {
        setIsMin3MonthReturnEnabled(!isMin3MonthReturnEnabled);
    };   

    const handleMinClosingPriceChange = (e) => {
        setMinClosingPrice(e.target.value);
    };

    const handleMinClosingPriceToggle = () => {
        setIsMinClosingPriceEnabled(!isMinClosingPriceEnabled);
    };

    const handleOperatingIndustryOptionChange = (event) => {
        setOperatingIndustry(event.target.value)
    };

    const handleOperatingIndustryToggle = () => {
        setIsOperatingIndustryEnabled(!isOperatingIndustryEnabled);
    };

    const handleApplyClick = () => {
        props.filterData(selectedMAs.includes(props.inputDataColumnNames[5]), selectedMAs.includes(props.inputDataColumnNames[6]), selectedMAs.includes(props.inputDataColumnNames[7]), selectedMAs.includes(props.inputDataColumnNames[8]), selectedMAs.includes(props.inputDataColumnNames[9]), maxDistance, minOverallRSRating, minRupeeVolumeLastDay, minAvgRupeeVolume30Days, minMarketCap, minFloatVolume, chartTypes, selectedHighBooleans.includes(props.inputDataColumnNames[15]), selectedHighBooleans.includes(props.inputDataColumnNames[16]), min1MonthRSRating, min3MonthRSRating, min1MonthReturn, min3MonthReturn, minClosingPrice, operatingIndustry, isMAsEnabled, isMaxDistanceEnabled, isMinOverallRSEnabled, isMinRupeeVolumeLastDayEnabled, isMinAvgRupeeVolume30DaysEnabled, isMinMarketCapEnabled, isMinFloatVolumeEnabled, isChartTypesEnabled, isHighBooleanEnabled, isMin1MonthRSEnabled, isMin3MonthRSEnabled, isMin1MonthReturnEnabled, isMin3MonthReturnEnabled, isMinClosingPriceEnabled, isOperatingIndustryEnabled);
        props.setDefaultValues([selectedMAs, maxDistance, minOverallRSRating, minRupeeVolumeLastDay, minAvgRupeeVolume30Days, minMarketCap, minFloatVolume, chartTypes, selectedHighBooleans, min1MonthRSRating, min3MonthRSRating, min1MonthReturn, min3MonthReturn, minClosingPrice, operatingIndustry, isMAsEnabled, isMaxDistanceEnabled, isMinOverallRSEnabled, isMinRupeeVolumeLastDayEnabled, isMinAvgRupeeVolume30DaysEnabled, isMinMarketCapEnabled, isMinFloatVolumeEnabled, isChartTypesEnabled, isHighBooleanEnabled, isMin1MonthRSEnabled, isMin3MonthRSEnabled, isMin1MonthReturnEnabled, isMin3MonthReturnEnabled, isMinClosingPriceEnabled, isOperatingIndustryEnabled]);
        props.onClose()  
    };

    const handleResetClick = () => {
        setSelectedMAs([])
        setMaxDistance(100);
        setMinOverallRSRating(0);
        setMinRupeeVolumeLastDay(0);
        setMinAvgRupeeVolume30Days(0);
        setMinMarketCap(0);
        setMinFloatVolume(0);
        setChartTypes(['DAILY', 'WEEKLY']);
        setSelectedHighBooleans(['1 Month High','3 Month High']);
        setMinn1MonthRSRating(0);
        setMin3MonthRSRating(0);
        setMin1MonthReturn(-30);
        setMin3MonthReturn(-30);
        setMinClosingPrice(0);
        setOperatingIndustry([]);
        setIsMAsEnabled(false);
        setIsMaxDistanceEnabled(false);
        setIsMinOverallRSEnabled(false);
        setIsMinRupeeVolumeLastDayEnabled(false);
        setIsMinAvgRupeeVolume30DaysEnabled(false);
        setIsMinMarketCapEnabled(false);
        setIsMinFloatVolumeEnabled(false);
        setIsChartTypesEnabled(false);
        setIsHighBooleanEnabled(false);
        setIsMin1MonthRSEnabled(false);
        setIsMin3MonthRSEnabled(false);
        setIsMin1MonthReturnEnabled(false);
        setIsMin3MonthReturnEnabled(false);
        setIsMinClosingPriceEnabled(false);
        setIsOperatingIndustryEnabled(false);
    };

    const handleCancelClick = () =>{
        props.onClose()
    };

    return (
    <Dialog open={true}>
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleCancelClick}
            style={{ position: 'absolute', top: 0, right: 15 }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle textAlign='center'>Configure Filters</DialogTitle>
        <DialogContent style={{ maxWidth: '1000px' }}>
            <div>
                <Checkbox
                    checked={isOperatingIndustryEnabled}
                    onChange={handleOperatingIndustryToggle}
                />
                <label>
                    Industry Group:
                    <Select
                            multiple
                            value={operatingIndustry}
                            onChange={handleOperatingIndustryOptionChange}
                            renderValue={(selected) => selected.join(', ')}
                            style={{ height: '45px', maxWidth: '300px', marginLeft: '8px', borderRadius:'8px'}} 
                            disabled={!isOperatingIndustryEnabled}
                            MenuProps={{
                                PaperProps: {
                                style: {
                                    maxHeight: 400, // Adjust the maxHeight as needed
                                },
                                },
                            }}
                        >
                        {props.operatingIndustryOptions.map((opIndustry) => (
                            <MenuItem key={opIndustry} value={opIndustry}>
                                <Checkbox checked={operatingIndustry.includes(opIndustry)}/>
                                {opIndustry}
                            </MenuItem>
                        ))}
                    </Select>
                </label>
            </div> 
            <div>
                <Checkbox
                    checked={isMAsEnabled}
                    onChange={handleMAsToggle}
                />
                <label>
                    Moving Averages:
                    <Select
                            multiple
                            value={selectedMAs}
                            onChange={handleMAOptionChange}
                            renderValue={(selected) => selected.join(', ')}
                            style={{ height: '45px', maxWidth: '300px', marginLeft: '8px', borderRadius:'8px'}} 
                            disabled={!isMAsEnabled}
                            MenuProps={{
                                PaperProps: {
                                style: {
                                    maxHeight: 250, // Adjust the maxHeight as needed
                                },
                                },
                            }}
                        >
                        <MenuItem key={props.inputDataColumnNames[5]} value={props.inputDataColumnNames[5]}>
                            <Checkbox checked={selectedMAs.includes(props.inputDataColumnNames[5])}/>
                            Above 200 Days MA
                        </MenuItem>
                        <MenuItem key={props.inputDataColumnNames[6]} value={props.inputDataColumnNames[6]}>
                            <Checkbox checked={selectedMAs.includes(props.inputDataColumnNames[6])}/>
                            Above 50 Days MA
                        </MenuItem>
                        <MenuItem key={props.inputDataColumnNames[7]} value={props.inputDataColumnNames[7]}>
                            <Checkbox checked={selectedMAs.includes(props.inputDataColumnNames[7])}/>
                            Above 20 Days MA
                        </MenuItem>
                        <MenuItem key={props.inputDataColumnNames[8]} value={props.inputDataColumnNames[8]}>
                            <Checkbox checked={selectedMAs.includes(props.inputDataColumnNames[8])}/>
                            Above 21 Days EMA
                        </MenuItem>
                        <MenuItem key={props.inputDataColumnNames[9]} value={props.inputDataColumnNames[9]}>
                            <Checkbox checked={selectedMAs.includes(props.inputDataColumnNames[9])}/>
                            Above 10 Days EMA
                        </MenuItem>
                    </Select>
                </label>
            </div>
            <div style={{ display: 'none'}}>
                <Checkbox
                    checked={isChartTypesEnabled}
                    onChange={handleChartTypesToggle}
                />
                <label>
                    Chart Types:
                    <Select
                            multiple
                            value={chartTypes}
                            onChange={handleChartTypeOptionChange}
                            renderValue={(selected) => selected.join(', ')}
                            style={{ height: '45px', marginLeft: '8px', borderRadius:'8px'}}
                            disabled={!isChartTypesEnabled}
                            MenuProps={{
                                PaperProps: {
                                style: {
                                    maxHeight: 250, // Adjust the maxHeight as needed
                                },
                                },
                            }}
                        >
                        <MenuItem key='DAILY' value='DAILY'>
                            <Checkbox checked={chartTypes.includes('DAILY')}/>
                            DAILY
                        </MenuItem>
                        <MenuItem key='WEEKLY' value='WEEKLY'>
                            <Checkbox checked={chartTypes.includes('WEEKLY')}/>
                            WEEKLY
                        </MenuItem>
                    </Select>
                </label>
            </div>           
            <div>
                <Checkbox
                    checked={isHighBooleanEnabled}
                    onChange={handleHighBooleanToggle}
                />
                <label>
                    New Highs:
                    <Select
                            multiple
                            value={selectedHighBooleans}
                            onChange={handleHighBooleanOptionChange}
                            renderValue={(selected) => selected.join(', ')}
                            style={{ height: '45px', marginLeft: '8px', borderRadius:'8px'}}
                            disabled={!isHighBooleanEnabled}
                            MenuProps={{
                                PaperProps: {
                                style: {
                                    maxHeight: 250, // Adjust the maxHeight as needed
                                },
                                },
                            }}
                        >
                        <MenuItem key='1 Month High' value='1 Month High'>
                            <Checkbox checked={selectedHighBooleans.includes('1 Month High')}/>
                            1 Month High
                        </MenuItem>
                        <MenuItem key='3 Month High' value='3 Month High'>
                            <Checkbox checked={selectedHighBooleans.includes('3 Month High')}/>
                            3 Month High
                        </MenuItem>
                    </Select>
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMaxDistanceEnabled}
                    onChange={handleMaxDistanceToggle}
                />
                <label>
                    Allowable Distance From 52w High(%):
                    <input
                    type="number"
                    className="curvy-input"
                    style={{ marginLeft: '10px', width: '150px'}}
                    value={maxDistance}
                    onChange={handleMaxDistanceChange}
                    disabled={!isMaxDistanceEnabled}
                    />
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMinOverallRSEnabled}
                    onChange={handleMinOverallRSToggle}
                />
                <label>
                    Overall RS Rating Cut Off:
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={minOverallRSRating}
                        onChange={handleMinOverallRSRating}
                        disabled={!isMinOverallRSEnabled}
                    />
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMin1MonthRSEnabled}
                    onChange={handleMin1MonthRSToggle}
                />
                <label>
                    1 Month RS Rating Cut Off:
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={min1MonthRSRating}
                        onChange={handleMin1MonthRSRatingChange}
                        disabled={!isMin1MonthRSEnabled}
                    />
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMin3MonthRSEnabled}
                    onChange={handleMin3MonthRSToggle}
                />
                <label>
                    3 Month RS Rating Cut Off:
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={min3MonthRSRating}
                        onChange={handleMin3MonthRSRatingChange}
                        disabled={!isMin3MonthRSEnabled}
                    />
                </label>
            </div>
            <div style={{ display: 'none'}}>
                <Checkbox
                    checked={isMinRupeeVolumeLastDayEnabled}
                    onChange={handleMinRupeeVolumeLastDayToggle}
                />
                <label>
                    Rupee Volume of Last Day (Cr.) {'>'}
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={minRupeeVolumeLastDay}
                        onChange={handleMinRupeeVolumeLastDayChange}
                        disabled={!isMinRupeeVolumeLastDayEnabled}
                    />
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMinAvgRupeeVolume30DaysEnabled}
                    onChange={handleMinAvgRupeeVolume30DaysToggle}
                />
                <label>
                    AVG Rupee Volume of 30 Days (Cr.) {'>'}
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={minAvgRupeeVolume30Days}
                        onChange={handleMinRupeeVolume30DaysAvgChange}
                        disabled={!isMinAvgRupeeVolume30DaysEnabled}
                    />
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMin1MonthReturnEnabled}
                    onChange={handleMin1MonthReturnToggle}
                />
                <label>
                    1 Month Return(%) {'>'}
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={min1MonthReturn}
                        onChange={handleMin1MonthReturnChange}
                        disabled={!isMin1MonthReturnEnabled}
                    />
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMin3MonthReturnEnabled}
                    onChange={handleMin3MonthReturnToggle}
                />
                <label>
                    3 Month Return(%) {'>'}
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={min3MonthReturn}
                        onChange={handleMin3MonthReturnChange}
                        disabled={!isMin3MonthReturnEnabled}
                    />
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMinMarketCapEnabled}
                    onChange={handleMinMarketCapToggle}
                />
                <label>
                    Market Cap (Cr.) {'>'}
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={minMarketCap}
                        onChange={handleMinMarketCapChange}
                        disabled={!isMinMarketCapEnabled}
                    />
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMinFloatVolumeEnabled}
                    onChange={handleMinFloatVolumeToggle}
                />
                <label>
                    Float Volume (Cr.) {'>'}
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={minFloatVolume}
                        onChange={handleMinFloatVolumeChange}
                        disabled={!isMinFloatVolumeEnabled}
                    />
                </label>
            </div>
            <div>
                <Checkbox
                    checked={isMinClosingPriceEnabled}
                    onChange={handleMinClosingPriceToggle}
                />
                <label>
                    Closing Price ₹ {'>'}
                    <input
                        type="number"
                        className="curvy-input"
                        style={{ marginLeft: '10px', width: '150px' }}
                        value={minClosingPrice}
                        onChange={handleMinClosingPriceChange}
                        disabled={!isMinClosingPriceEnabled}
                    />
                </label>
            </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
            <Button  style={{ textAlign: 'center', borderRadius: '8px'}} onClick={handleResetClick}>Reset Configuration</Button>
            <Button  style={{ textAlign: 'center', borderRadius: '8px'}} onClick={handleApplyClick} variant="contained" color="primary">
            Apply Configurations
            </Button>
        </DialogActions>
    </Dialog>
  )
}
