import React,{useState} from 'react'
import { Button, TextField, Typography, IconButton, InputAdornment} from '@mui/material';
import { styled } from '@mui/system';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {auth} from '../Firebase'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, sendPasswordResetEmail, sendEmailVerification} from "firebase/auth";
import { useNavigate} from 'react-router-dom';
import { doc, setDoc, collection, getDocs} from "firebase/firestore"; 
import {db} from '../Firebase'

const AuthContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
});
  
const AuthForm = styled('div')({
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    borderRadius: '8px',
    width: '300px',
    textAlign: 'center',
});

const InputGroup = styled('div')({
    marginBottom: '15px',
});

export default function Login(props) {

    const navigate = useNavigate();
    const [isForgotPasswordMode, setIsForgotPasswordMode] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);

    const toggleAuthMode = () => {
        setIsSignUp(!isSignUp);
        setShowPassword(false);
        setError(null);
    };

    const toggleForgotPasswordMode = () => {
        setIsForgotPasswordMode(!isForgotPasswordMode);
    };

    const handleGoBackClick = () => {
        setIsForgotPasswordMode(!isForgotPasswordMode);
        setError(null);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePassswordReset = async() => {
        try {
            let userExists = false;
            const usersCollectionRef = collection(db, 'users');
            const querySnapshot = await getDocs(usersCollectionRef);
            querySnapshot.forEach((doc) => {
                if(doc.id === email){
                    userExists = true;
                }
            });
            if(userExists){
                await sendPasswordResetEmail(auth, email);
                alert('Password reset email sent successfully.');
                setError(null);
            }
            else{
                setError('User does not exists')
            }
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }

    const handleSubmit = async() => {
        
        if (isSignUp)
        {
            if(password !== confirmPassword){
                setError('Passwords Do Not Match');
                return;
            }

            const lowercaseRegex = /[a-z]/;
            const uppercaseRegex = /[A-Z]/;
            const numberRegex = /[0-9]/;
            const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
          
            if(
              password.length < 8 ||
              !lowercaseRegex.test(password) ||
              !uppercaseRegex.test(password) ||
              !numberRegex.test(password) ||
              !specialCharRegex.test(password)
            ) {
              setError(
                'Password must be at least 8 characters and include lowercase, uppercase, number, and special character'
              );
              return;
            }

            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                sendEmailVerification(user);
                updateProfile(user, { displayName: userName})
                .then(() => {
                    try {
                        const userDocRef = doc(db, 'users', user.email);
                        setDoc(userDocRef, {            
                        });
                        alert('Account created successfully. Please check your email to verify using the link before logging in.');
                    } catch (e) {
                        console.error("Error adding document: ", e);
                    }
                })
                .catch((updateProfileError) => {
                    setError('Invalid User Name')
                });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                if (errorCode === 'auth/email-already-in-use') {
                    setError('Email already in use')
                } else if (errorCode === 'auth/invalid-email') {
                    setError('Invalid email address')
                } else if (errorCode === 'auth/weak-password') {
                    setError('Password is Weak')
                } else {
                    setError(errorMessage);
                }
            });
        } 
        else
        {
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              const user = userCredential.user;
              if(user.emailVerified){
                props.setUser(user);
                localStorage.setItem('user', JSON.stringify(user));
                navigate('/');
              }else{
                setError('Email not verified. Please check your inbox and verify using the link received')
              }

            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              if (errorCode === 'auth/user-not-found' || errorCode === 'auth/invalid-credential' || errorCode === 'auth/invalid-email') {
                setError('Invalid email ID or password')
              } else {
                setError(errorMessage);
              }
            });
        }
    };

    return (
        <AuthContainer>
            <AuthForm>
                {!isForgotPasswordMode && (
                    <div>
                        <Typography variant="h6">{isSignUp ? 'Sign Up' : 'Login'}</Typography>
                        <InputGroup>
                            <TextField
                                type="email"
                                label="Email"
                                style={{ paddingTop: '10px' }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                            />
                        </InputGroup>
                        {isSignUp && (
                            <InputGroup>
                                <TextField
                                    label="User Name" 
                                    style={{ paddingTop: '10px' }}
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    fullWidth
                                />
                            </InputGroup>
                        )}
                        <InputGroup>
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                label="Password"
                                style={{ paddingTop: '10px' }}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility}>
                                          {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                }}
                            />
                        </InputGroup>
                        {!isSignUp && (
                            <Typography
                                    style={{ cursor: 'pointer', color: '#1e88e5', textAlign:'left'}}
                                    onClick={toggleForgotPasswordMode}
                                >
                                    forgot password
                            </Typography>
                        )}
                        {isSignUp && (
                            <InputGroup>
                                <TextField
                                    type="password"
                                    label="Confirm Password"
                                    style={{ paddingTop: '10px' }}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    fullWidth
                                />
                            </InputGroup>
                        )}
                        <Button variant="contained" style={{ backgroundColor: '#1e88e5'}} onClick={handleSubmit}>
                            {isSignUp ? 'Sign Up' : 'Login'}
                        </Button>
                        {error && (
                            <Typography color="error" style={{ marginTop: '10px' }}>
                                {error}
                            </Typography>
                        )}
                        <div style={{ display: 'flex', alignItems: 'center', marginTop:'20px'}}>
                            <Typography style={{ marginRight: '10px' }}>
                                {isSignUp ? 'Already have an account?' : "Don't have an account?"}
                            </Typography>
                            <Typography
                                style={{ cursor: 'pointer', color: '#1e88e5' }}
                                onClick={toggleAuthMode}
                            >
                                {isSignUp ? 'Login' : 'Sign Up'}
                            </Typography>
                        </div>
                    </div>
                )}
                {isForgotPasswordMode && (
                    <div>
                        <Typography variant="h6" sx={{marginBottom:'20px'}}>Enter your email and we'll send you a link to get back into your account.</Typography>
                        <InputGroup>
                            <TextField
                                type="email"
                                label="Email"
                                style={{ paddingTop: '10px' }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                            />
                        </InputGroup>
                        <Button style={{color: '#1e88e5', marginRight: '8px'}} onClick={handleGoBackClick}>
                            Go Back
                        </Button>
                        <Button variant="contained" style={{ backgroundColor: '#1e88e5'}} onClick={handlePassswordReset}>
                            Email me
                        </Button>
                        {error && (
                            <Typography color="error" style={{ marginTop: '10px' }}>
                                {error}
                            </Typography>
                        )}
                    </div>
                )}
            </AuthForm>
        </AuthContainer>
    )
}
