import React from 'react'
import { Avatar, Button, Popover, Typography } from '@mui/material';
import { signOut } from "firebase/auth";
import {auth} from '../Firebase'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export default function LoggedInUserDetailsDialog(props) {

    const handleSignOut = async() => {

        signOut(auth).then(() => {
            props.setUser(null);
            localStorage.removeItem('user');
        }).catch((error) => {
            console.log('error');
        });              
        props.onClose();
    };
    

    return (
        <Popover
            open={props.open}
            onClose={props.onClose}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'bottom', 
                horizontal: 'center', 
            }}
            transformOrigin={{
                vertical: 'top', // Align to the top of the Popover
                horizontal: 'center',
            }}
            sx={{padding:'5px'}}
        >
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt="User Avatar" style={{ width: '40px', height: '40px', marginBottom:'10px', backgroundColor: '#008080', color: '#ecf0f1'}}>
                        {props.user.displayName.charAt(0).toUpperCase()}
                    </Avatar>    
                    <Typography variant="h6" style={{ marginBottom: '5px', marginLeft: '10px' }}>
                        {props.user.displayName}
                    </Typography>
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ExitToAppIcon />}
                    onClick={handleSignOut}
                    style={{ textTransform: 'none' }}
                >
                    Sign Out
                </Button>
            </div>
        </Popover>
    )
}
